import {
  Component,
  OnInit,
  inject,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { RoutesModulesExternalsEnum } from 'src/app/shared/enum/routes-modules-externals';


@Component({
  selector: 'app-my-studies-consultancy',
  templateUrl: './my-studies-consultancy.component.html',
  styleUrls: ['./my-studies-consultancy.component.scss'],
})
export class MyStudiesConsultancyModalComponent implements OnInit  {
  readonly #modal = inject(NzModalRef);
  @Input() userName: string = "";
  @Input() buttonAction: any;

  arrayImageDelete: any[] = [];
  fileList: NzUploadFile[] = [];
  fileListStorageRepite: NzUploadFile[] = [];
  bodyFormat: any;

  modalHeight: any

  constructor(
    private router: Router
  ) {}


  ngOnInit(): void {
    const name = this.userName.split(" ")[0]
    this.userName = name
  }

  executeButtonAction() {
    localStorage.setItem('CONSULTORES_BEM_VINDO_MODAL', 'S');
    this.#modal.destroy();
    this.router.navigate([`${RoutesModulesExternalsEnum.CONSULTANCY}/${RoutesModulesExternalsEnum.MY_STUDIES}`])
  }
  close(){
    this.#modal.destroy();
  }

}
