<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" *ngIf="communication">
  <div class="commsResume">
    <h2 class="commsResume__title">
      <!-- Resumo da comunicação -->
      {{"projects.communication.resume" | translate }}
    </h2>

    <div class="commsResume__items">
      <div class="commsResume__items__item">
        <h3 class="commsResume__items__item__title">
          <!-- Sobre o projeto -->
          {{ "projects.communication.about-project" | translate }}
        </h3>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Nome do projeto -->
            {{ "projects.communication.project-name" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.project?.name ? communication.project?.name : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Responsável pelo projeto -->
            {{ "projects.communication.project-responsible" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.project?.userLegalRepresentative?.name ? communication.project?.userLegalRepresentative?.name : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Localização -->
            {{ "projects.communication.location" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ getLocation() }}
          </p>
        </div>
      </div>

      <div class="commsResume__items__item">
        <h3 class="commsResume__items__item__title">
          <!-- Sobre o processo -->
          {{"projects.communication.about-process" | translate }}
        </h3>
        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Processo vinculado -->
            {{"projects.communication.linked-process" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.projectLicensing?.name ? communication.projectLicensing?.name : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Órgão -->
            {{"projects.communication.organ" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.organ ? communication.organ : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Número do processo -->
            {{"projects.communication.process-number" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.processNumber ? communication.processNumber : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Atividades vinculadas -->
            {{"projects.communication.linked-activities" | translate }}
          </p>
          <p *ngIf="!communication.activities || communication.activities?.length === 0">-</p>
          <ul>
            <li class="commsResume__items__item__row__description"
              *ngFor="let activity of communication.activities">
              {{ activity.title }}
            </li>
          </ul>
        </div>
      </div>

      <div class="commsResume__items__item">
        <h3 class="commsResume__items__item__title">
          <!-- Sobre a comunicação -->
          {{"projects.communication.about" | translate }}
        </h3>
        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Tipo de comunicação -->
            {{"projects.communication.type" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.communicationType?.description ? communication.communicationType?.description : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Título da comunicação -->
            {{"projects.communication.title" | translate}}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.title ? communication.title : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Responsável pelo recebimento -->
            {{
              type === 'REC' ?
                ("projects.communication.responsible-receiving" | translate) :
                ("projects.communication.responsible-sending" | translate)
            }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.responsibleUser?.name ? communication.responsibleUser?.name : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Meio de recebimento -->
            Meio de {{ communication.communicationType?.code === 'REC' ? 'recebimento' : 'envio' }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.returnMean?.name ? communication.returnMean?.name : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Nº Identificador da correspondência -->
            {{"projects.communication.correspondence-id-number" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.identificationNumber ? communication.identificationNumber : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Número do protocolo -->
            {{"projects.communication.protocol-number" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.protocolNumber ? communication.protocolNumber : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Nº Registro Eletrônico (Ex.: SEI) -->
            {{"projects.communication.electronic-registration-number" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.registerNumber ? communication.registerNumber : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Outro número de identificação -->
            {{"projects.communication.other-id-number" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.otherIdentificationNumber ? communication.otherIdentificationNumber : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Data de recebimento -->
            Data de {{ communication.communicationType?.code === 'REC' ? 'recebimento' : 'envio' }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.date ? (communication.date | date: 'dd/MM/yyyy') : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Necessita resposta? -->
            {{"projects.communication.need-answer" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ getOrganResponse() }}</p>
        </div>

        <div class="commsResume__items__item__row" *ngIf="this.communication.organResponse">
          <p class="commsResume__items__item__row__title">
            <!-- Necessita resposta? -->
            Prazo para resposta (dias corridos)
          </p>
          <p class="commsResume__items__item__row__description">
            {{ this.communication.deadlineDays ? this.communication.deadlineDays : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row" *ngIf="this.communication.organResponse">
          <p class="commsResume__items__item__row__title">
            <!-- Necessita resposta? -->
            Data limite para resposta
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.deadLineResponseDate ? (communication.deadLineResponseDate | date: 'dd/MM/yyyy') : '-' }}
          </p>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Comunicações vinculadas -->
            {{"projects.communication.linked-communications" | translate }}
          </p>
          <ul>
            <li class="commsResume__items__item__row__description"
              *ngFor="let communication of communication.connections">
              {{ communication.name ? communication.name: '-' }}
            </li>
          </ul>
        </div>

        <div class="commsResume__items__item__row">
          <p class="commsResume__items__item__row__title">
            <!-- Descrição -->
            {{"projects.communication.description" | translate }}
          </p>
          <p class="commsResume__items__item__row__description">
            {{ communication.description ? communication.description : '-' }}
          </p>
        </div>
      </div>

      <ng-container *ngIf="communication.communicationType?.code === 'CLA'">
        <div class="commsResume__items__item">
          <h3 class="commsResume__items__item__title">
            <!-- Sobre a licença -->
            {{"projects.communication.about-license" | translate }}
          </h3>
          <div class="commsResume__items__item__row">
            <p class="commsResume__items__item__row__title">
              <!-- Número do documento -->
              {{"projects.communication.document-id" | translate }}
            </p>
            <p class="commsResume__items__item__row__description">
              {{ communication.licenseNumber ? communication.licenseNumber : '-' }}
            </p>
          </div>

          <div class="commsResume__items__item__row">
            <p class="commsResume__items__item__row__title">
              <!-- Obrigações -->
              {{'projects.communication.obligations' | translate }}
            </p>
            <p class="commsResume__items__item__row__description">
              {{ communication.existsObligation === true ? 'Sim' : 'Não' }}
            </p>
          </div>

          <div class="commsResume__items__item__row">
            <p class="commsResume__items__item__row__title">
              <!-- Data de emissão -->
              {{'projects.communication.issue-date' | translate }}
            </p>
            <p class="commsResume__items__item__row__description">
              {{ communication.licenseEmissionDate !== '' ? (communication.licenseEmissionDate | date:
              'dd/MM/yyyy') : '' }}
            </p>
          </div>

          <div class="commsResume__items__item__row">
            <p class="commsResume__items__item__row__title">
              <!-- Data de validade -->
              {{'projects.communication.expiration-date' | translate }}
            </p>
            <p class="commsResume__items__item__row__description">
              {{ communication.licenseExpirationDate !== '' ? (communication.licenseExpirationDate | date:
              'dd/MM/yyyy') : '' }}
            </p>
          </div>

          <div class="commsResume__items__item__row">
            <p class="commsResume__items__item__row__title">
              <!-- Prazo de renovação -->
              {{'projects.communication.renewal-deadline' | translate }}
            </p>
            <p class="commsResume__items__item__row__description">
              {{ communication.renewalDeadline }} dias
            </p>
          </div>
        </div>
      </ng-container>

      <div class="commsResume__items__item">
        <h3 class="commsResume__items__item__title">
          <!-- Anexos -->
          {{'projects.communication.attachments' | translate }}
        </h3>
        <div class="fileList">
          <a class="fileLink" (click)="downloadFile(attachment.id,attachment.name)"
            *ngFor="let attachment of attachments" nz-tooltip nzTooltipTitle="{{attachment.name}}"
            [nzTooltipPlacement]="['topLeft', 'leftTop']">
            <app-svg-icon *ngIf="(attachment.name?.includes('.shp'))" icon="map_icon" class="iconAlignCenter mr-10">
            </app-svg-icon>
            <app-svg-icon [width]="16" [height]="16" *ngIf="(!attachment.name?.includes('.shp'))"
              icon="uploaded_document-icon" class="iconAlignCenter mr-10">
            </app-svg-icon>
            <span class="fileName">{{attachment.name.length > 40 ? (attachment.name | slice:0:30) + '...' +
              (attachment.name | slice: attachment.name.length - 10 : attachment.name.length) : attachment.name}}</span>
          </a>
        </div>
      </div>

    </div>
  </div>
</div>
