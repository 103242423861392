import { selectPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.selector';

import { Component,  OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {Page} from "../../../shared/ngrx/breadcrumb/pages.model";
import { RoutesEnum } from 'src/app/shared/enum/routes.enum';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {
  pages: Observable<Page[]>;
  routesEnum = RoutesEnum;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.pages = this.store.select(selectPages);
    // this.pages.subscribe(pages =>
  }

  toPage(url: string): void {
    // Separar a URL em rota e parâmetros de consulta
    const [route, queryString] = url.split('?');

    // Inicializa um objeto vazio para os parâmetros
    let queryParams = {};

    // Se houver parâmetros de consulta, transforme-os em um objeto
    if (queryString) {
      queryParams = queryString.split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value;
        return params;
      }, {});
    }

    // Navega para a rota base com os parâmetros de consulta
    if(route !== ""){
      this.router.navigate([route], { queryParams });
    }

  }

}
