import keycloakDevConfig from './keycloak-dev.config'

export const environment = {
  production: false,
  keycloak: keycloakDevConfig,
  apiUrl: 'https://api.dev.smartlicensingweb.com',
  workspace: '5e45386e-6583-49fc-9989-a6577193f157',
  dashStudy: '7458cafb-2d2a-42ca-bf06-ffa8f532a26f',
  dashSuppliers: 'eae41a8c-337a-4ad5-b4c2-c69c9b3fef1c',
  dashLicensing: '44c33467-fbc0-4a20-8d7f-bdb924e4f896',
  dashManager: '7a6411c5-f79a-4413-8ef3-df93020c14ba',
  dashRipla: 'a4306164-dcc4-4eba-9c8d-dbd837e7dcbb',
  dashNegocios: 'b1a4753c-5904-4950-ab04-16cc8084d8ae',
  uploadFileSize: 1024,
  activeSuppliers: true
}
