<div nz-row nz-col nzSpan="24" class="generic_modal">
  <header class="generic_modal-head">
    <img
      class="generic_modal-icon" *ngIf="iconName"
      [src]="'/assets/icons/' + iconName + '.svg'" alt="modal-icon"/>
    <strong class="generic_modal-title">{{ modalTitle }}</strong>
  </header>

  <p *ngIf="modalSubTitle" class="subtitle" [innerHTML]="modalSubTitle"></p>

  <div class="warning" *ngIf="warningText">
    <img src="/assets/icons/icon_caution_circle_warning_red.svg" alt="warning-icon">
    <p>{{warningText}}</p>
  </div>

  <p [innerHTML]="modalText" class="generic_modal-content"></p>

  <span *ngIf="actionConfirmationText" class="generic_modal-action-confirmation-text">{{ actionConfirmationText }}</span>

  <span *ngIf="informativeText" class="generic_modal-informative-text" [innerHtml]="informativeText"></span>

  <div *ngIf="customComponent">
    <ng-container *ngTemplateOutlet="customComponent; context: { parent: parentContext }"></ng-container> 
  </div>

  <span class="required" *ngIf="errorMessage">{{ errorMessage }}</span>

  <section class="generic_modal-action_buttons">
    <app-button-icon-secondary [text]="cancelationButtonText" (click)="cancelAction()" (keydown)="cancelAction()"></app-button-icon-secondary>
    <app-button-icon-primary [text]="confirmButtonText" (click)="executeAction()" (keydown)="executeAction()"></app-button-icon-primary>
  </section>
</div>
