import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetTasksTableResponse, MyProjectsResponse, TasksListRequestParams } from '../interface/task-paged-response';
import { GET_PROJECTS, GET_TASKS } from 'src/app/shared/constants/tasks/tasksApiEndPointsConstants';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(
    private readonly http: HttpClient
  ) { }

  getTasks(filterParams: TasksListRequestParams, page: number = 0): Observable<GetTasksTableResponse> {
    let params: any = {};
    if (filterParams.filter) {
      Object.keys(filterParams.filter).forEach(key => {
        const value = filterParams.filter?.[key];
        if (Array.isArray(value)) {
          params[key] = value.join(',');
        } else if (value !== undefined && value !== null && value !== '') {
          params[key] = value;
        }
      });
    }
    return this.http.get<GetTasksTableResponse>(`${GET_TASKS}?page=${page}&size=20&nextDayCount=${filterParams.nextDayCount}`, { params });
  }

  getProjects(): Observable<MyProjectsResponse> {
    return this.http.get<MyProjectsResponse>(`${GET_PROJECTS}`);
  }
}
