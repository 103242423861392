import { Component, Input, OnInit } from "@angular/core";

type TypologyIconTypes = "briquetagem" | "desc_barragens" | "energia" | "ferrovias" | "mineracao" | 
  "outros" | "pelotizacao" | "pesq_mineral" | "portos" | "sondagem";

@Component({
  selector: "app-typology-icon",
  templateUrl: "./typology-icon.component.html",
  styleUrls: ["./typology-icon.component.scss"]
})
export class TypologyIcon implements OnInit {
  @Input() typologyName!: string;
  @Input() iconSize: number = 32;
  bgColor: string;

  mapper: { [key: string]: { color: string, iconName: TypologyIconTypes }} = {
    "briquetagem": { color: "#FF8826", iconName: "briquetagem" },
    "descaracterização": { color: "#18D2BA", iconName: "desc_barragens" },
    "energia": { color: "#03B0CA", iconName: "energia" },
    "ferrovias": { color: "#22C55E", iconName: "ferrovias" },
    "mineração": { color: "#4338CA", iconName: "mineracao" },
    "outros": { color: "#007182", iconName: "outros" },
    "pelotização": { color: "#730394", iconName: "pelotizacao" },
    "pesquisa": { color: "#009A87", iconName: "pesq_mineral" },
    "portos": { color: "#2078A9", iconName: "portos" },
    "sondagem": { color: "#DB2777", iconName: "sondagem" }
  }

  ngOnInit(): void {
    const iconInfos = this.mapTypologyToIcon();
    this.bgColor = iconInfos.color;
    this.typologyName = iconInfos.iconName;
  }

  mapTypologyToIcon() {
    return this.mapper[this.typologyName.toLowerCase().split(" ")[0]];
  }

  getIconPath(iconName: string): string {
    return `./assets/icons/typology/${iconName}.svg`;
  }

  getIconSize(isInner: boolean): string {
    if (isInner) {
      return `${(this.iconSize) * 0.75}px`;
    } else {
      return `${this.iconSize}px`;
    }
  }
}