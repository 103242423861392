import { LocalStorageService } from './../../../../shared/services/local-storage.service';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { CardTitle, IChatAILocalStorage } from '../../interface/chat-ia-view';
import { IChat, IMessage } from '../../interface/commons';
import { ChatAiService } from '../../service/chat-ai.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IfeedbackModalSendEmail, IstatusModalSendEmail } from '../../components/chat-view/modal-confirm-send-email/modal-confirm-send-email.component';
import { ConversationChatAIComponent } from '../../components/chat-view/conversation-chat-ia/conversation-chat-ia.component';
import { UserService } from 'src/app/shared/services/user.service';
import { StartChatAIComponent } from '../../components/chat-view/start-chat-ai/start-chat-ai.component';
import { NgrxAiUtilsService } from '../../service/ngrx-ai/ngrx-ai-utils.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
})
export class ChatComponent {

  @ViewChild(ConversationChatAIComponent) conversationChatAi!: ConversationChatAIComponent;
  @ViewChild(StartChatAIComponent) startChatAi!: StartChatAIComponent;
  
  private previousUrl: string | null = null;

  showChatAI = false;
  showChatAIClick = false;
  showLoading = false;
  showActionBar = false;
  showConversation = false;
  showModalSendEmail = false;
  isLoading: boolean = false;
  maximize = false;
  isSendingFile: boolean = false;
  progressValue: number = 70;
  chatId: string;

  loggedUser;

  messages: IMessage[] = [];
  feedbackEmail: IfeedbackModalSendEmail | null;

  configCardTitle: CardTitle = {
    title: 'Pergunte à IA',
    subtitle: 'Use a IA para encontrar informações rapidamente.',
    editable: false
  };

  currentChat: IChat | null;
  actionCallCount: number = 0;

  constructor(
    private chatAiService: ChatAiService,
    private modal: NzModalService,
    private service: UserService,
    private readonly viewContainerRef: ViewContainerRef,
    private ngrxAiUtilsService: NgrxAiUtilsService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.cleanAttachmentWhenChangeRoute();
  }

  cleanAttachmentWhenChangeRoute(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.previousUrl && this.previousUrl !== event.url) {
         this.ngrxAiUtilsService.dispatchAttachmentListOnStore([]);
        }
        this.previousUrl = event.url;
      }
    });
  }

  showTooltipProcess() {
    setTimeout(() => {
      const inputElement = document.getElementById('idTooltipChatAi') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
        inputElement.click();
      }
    }, 100);
  }

  closeTooltipProcess() {
    const buttonElement = document.querySelector('.tooltipChatAi button') as HTMLButtonElement;
    if (buttonElement) {
      buttonElement.click();
    }
  }

  showTooltipFail() {
    if (!this.showChatAI) {
      setTimeout(() => {
        const inputElement = document.getElementById('idTooltipChatAiFail') as HTMLInputElement;
        if (inputElement) {
          inputElement.focus();
          inputElement.click();
        }
      }, 100);
    }
  }

  closeTooltipFail() {
    const buttonElement = document.querySelector('.tooltipChatAiFail button') as HTMLButtonElement;
    if (buttonElement) {
      buttonElement.click();
    }
  }

  showChat(): void {
    if(!this.checkChatAiConfiguration() && this.showConversation){
      this.showModalSendEmail = true;
      this.showChatAIClick = true;
    } else {
      this.showChatAI = !this.showChatAI;
      this.showChatAIClick = false;
      this.callAction('back');

      if (!this.showChatAI && this.isSendingFile) {
        this.showLoading = true;
      } else {
        this.showLoading = false;
      }
      this.closeTooltipProcess();
    }
    this.getUser();
    this.ngrxAiUtilsService.dispatchIsChatOpenOnStore(this.showChatAI);
  }

  checkChatAiConfiguration(): boolean{
    let chatAiConfiguration = this.localStorageService.getItem('chatAiConfiguration') as IChatAILocalStorage;
    return !!chatAiConfiguration;
  }

  onHidenTooltip(): void {
    this.showChatAI = true;
  }

  returnProgressBar(): string {
    return `--progress: ${this.progressValue}`;
  }

  callAction(action: string): void {
    this.actionCallCount++;
    switch (action) {
      case 'maximize':
        this.maximize = !this.maximize;
        break;
      case 'new-chat':
        if (this.messages.length > 1 || !this.messages[0].chat_id) {
          this.startConversation();
        }
        break;
      case 'email':
        this.callChatAiEmail(false);
        break;
      case 'back':
        if (this.actionCallCount >= 2 && this.chatId && this.chatId.length > 0) {
          let chatAiConfiguration = this.localStorageService.getItem('chatAiConfiguration') as IChatAILocalStorage;
          if (chatAiConfiguration && chatAiConfiguration.showModalSendEmail === true) {
            this.showModalSendEmail = true;
          } else {
            this.goBackChatAI();
          }
        } else {
          this.goBackChatAI();
        }
        this.maximize = false;
        break;
    }
  }

  goBackChatAI() {
    this.showConversation = false;
    this.showActionBar = false;
    this.initTitleForHistoric();
    this.currentChat = null;
    this.feedbackEmail = null;
  }

  onRowClick(object: IChat) {
    this.isLoading = true;
    this.showConversation = true;
    this.showActionBar = true;
    this.chatId = object.chat_id;
    this.messages = [];
    this.chatAiService.callChatAiEndpoint(null, `/chats/history/?chat_id=${object.chat_id}`).subscribe({
      next: (chatHistory) => {
        this.messages = chatHistory.messages
      },
      complete: () => {
        this.isLoading = false;
      }
    })

    this.currentChat = object;
    this.configCardTitle = {
      id: object.chat_id,
      title: object.name,
      subtitle: '',
      editable: true
    }
  }

  startConversation() {
    this.showConversation = true;
    this.showActionBar = true;
    this.messages = [];
    this.chatId = '';
    this.messages.push({
      "chat_id": "",
      "content": `Oi, ${this.loggedUser.name}! Como posso te ajudar?`,
      "message_id": "",
      "role": "ai"
    });
    this.initTitleForHistoric();
    this.feedbackEmail = null;
    setTimeout(() => {
      this.conversationChatAi?.restartChat();
    }, 1);
  }

  initTitleForHistoric() {
    this.configCardTitle = {
      title: 'Pergunte à IA',
      subtitle: 'Use a IA para encontrar informações rapidamente.',
      editable: false
    }
  }

  fileSendingStatus($event): void {
    if (this.isSendingFile) {
      this.poolRequestForFileStatus();
    }
    this.isSendingFile = $event;
  }

  onUpdateChatIdBySendMessage(event):void {
    this.chatId = event;
  }

  poolRequestForFileStatus(): void {
    const operationId = sessionStorage.getItem('operation_id');

    this.chatAiService.callChatAiEndpoint(null, `/files/operation/?operation_id=${operationId}`).subscribe({
      next: (response) => {
        if (response.status === "finished") {
          this.isSendingFile = false;
          this.showLoading = false;

          if (!this.showChatAI) {
            this.showTooltipProcess();
          }

          this.messages.push({
            chat_id: this.chatId,
            content: "O que deseja saber sobre os documentos?",
            message_id: Math.random().toString(),
            role: "ai"
          })

        if (this.conversationChatAi) {
          this.conversationChatAi.chatTargetFiles = response.urls
        }
      } else if (response.status === "finished with errors") {
        this.isSendingFile = false;
        this.showLoading = false;
        this.messages.push({
          chat_id: this.chatId,
          content: "Houve um erro ao ler os documentos. Por favor, tente novamente.",
          message_id: Math.random().toString(),
          role: "ai"
        })
        this.conversationChatAi.restartChat()
        } else {
          setTimeout(() => this.poolRequestForFileStatus(), 15000);
        }
      },
      error: (response) => {
        this.isSendingFile = false;
        this.showLoading = false;
        this.messages.push({
          chat_id: this.chatId,
          content: "Houve um erro ao ler os documentos. Por favor, tente novamente.",
          message_id: Math.random().toString(),
          role: "ai"
        })
        if (!this.showChatAI) {
          this.showTooltipFail();
        }
        this.conversationChatAi.restartChat()
      }
    })
  }

  sendEmail(event: IstatusModalSendEmail) {
    this.showModalSendEmail = !event.closeModalButton;
    this.setChatAiConfigurationLocalStorage();

    if (event.sendEmail) {
      this.callChatAiEmail();
    }

    if (event.closeModalButton) {
      this.showModalSendEmail = false;
      if(this.showChatAIClick){
        if (this.showChatAI && this.isSendingFile) {
          this.showLoading = true;
        } else {
          this.showLoading = false;
        }
        this.showChatAI = !this.showChatAI;
        this.closeTooltipProcess();
        this.goBackChatAI();
      } else {
        this.goBackChatAI();
      }
    }
  }

  setChatAiConfigurationLocalStorage(): void {
    const chatAiConfig = this.localStorageService.getItem('chatAiConfiguration') as IChatAILocalStorage;

    let informationStorage: IChatAILocalStorage = {
      chat_id: [],
      showModalSendEmail: this.showModalSendEmail,
      used: chatAiConfig?.used
    }

    this.localStorageService.setItem('chatAiConfiguration', informationStorage);
  }

  onSendEmailByNewChat(event) {
    this.currentChat = event;
  }

  callChatAiEmail(goBackChatAI: boolean = true) {
    if (!this.currentChat) return;

    let params: {chatId: string; title: string} ={chatId: this.currentChat.chat_id, title: this.currentChat.name};
    this.chatAiService.callChatAiEmail(params).subscribe({
      next: (response) => {},
      error: (error) => {
        this.feedbackEmail = { message: 'Infelizmente não foi possível enviar o e-mail. Favor, tentar novamente.' };
        this.cleanFeedbackEmail(5500);
      },
      complete: () => {
        if (goBackChatAI) {
          this.showModalSendEmail = false;
          this.feedbackEmail = { message: 'A conversa foi enviada para seu e-mail.' };
          setTimeout(() => { this.goBackChatAI(); }, 2000);
          this.cleanFeedbackEmail(5500);
        } else {
          this.showModalSendEmail = false;
          this.feedbackEmail = { message: 'A conversa foi enviada para seu e-mail.' };
          this.cleanFeedbackEmail(5500);
        }
      }
    });
  }

  getUser() {
    const userSession = sessionStorage.getItem('user');
    this.loggedUser = userSession ? JSON.parse(userSession) : null;
  }

  cleanFeedbackEmail(time: number): void {
    setTimeout(() => { this.feedbackEmail = null; }, time);
  }

}
