import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ITeam, ITeamProfessional } from 'src/app/modules/suppliers/interface/consultancy-configuration-view';
import { AddTeamModalComponent } from 'src/app/modules/suppliers/modal/consultancy-configuration/delivery-study-modal-add-team/add-team-modal.component';
import { GenericExclusionModalComponent } from 'src/app/modules/suppliers/modal/consultancy-configuration/generic-exclusion-modal/generic-exclusion-modal.component';
import { ConsultingConfigurationServices } from 'src/app/modules/suppliers/service/consultancy-configuration.services';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';

interface Professional {
  id: number;
  name: string;
  email: string;
}

@Component({
  selector: 'app-team-consultancy',
  templateUrl: './team-consultancy.component.html',
  styleUrl: './team-consultancy.component.scss'
})
export class TeamConsultancyComponent implements OnInit {

  @Input() consultancies: ITeam[] = []
  @Input() supConfigurationId: any
  @Input() consultancyId: number
  @Input() activityId: any;
  @Input() isValeView: boolean = true;
  
  @ViewChild('emailElement') emailElement!: ElementRef;

  consultancy: ITeamProfessional;

  isDisabled: boolean = false
  idToExclude: number = 0;
  tooltipMessage: string = "Copiar e-mail"; // Mensagem inicial do tooltip
  tooltipColor: string = "";
  extendedTeamView: boolean = true;
  
  loggedUser;

  constructor(
    private readonly modal: NzModalService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly consultancyService: ConsultingConfigurationServices,
    private readonly alertService: AlertMessageService,
    private readonly cdr: ChangeDetectorRef
  ){}

  ngOnInit(): void {
    const userSession = sessionStorage.getItem('loggedUser');
    this.loggedUser = userSession ? JSON.parse(userSession) : null;

    this.getTeamConsultancy();

    if (this.isValeView) this.extendedTeamView = false;
  }

  addNewTeamConsultancy(consultancy?: ITeamProfessional, isEdit: boolean = false): void {
    const modal = this.modal.create<AddTeamModalComponent>({
      nzClosable: false,
      nzTitle: '',
      nzWidth: 640,
      nzContent: AddTeamModalComponent,
      nzKeyboard: false,
      nzViewContainerRef: this.viewContainerRef,
      nzCentered: true,
      nzMaskClosable: false,
      nzFooter: null,
    });

    const existingMembers: ITeamProfessional[] = this.consultancies.filter(consult => (
      consult.name?.toLowerCase() !== "value"
    )).flatMap(consult => consult.supConfigTeams);
    const existingMembersEmail: string[] = existingMembers.map(member => member.userEmail);

    const instance = modal.getContentComponent();

    if (consultancy && isEdit) {
      instance.consultancy = consultancy;
    };

    instance.consultancyId = this.consultancyId;
    instance.alreadyAddedMembers = existingMembersEmail;
    modal.afterClose.subscribe((professionalData) => {
      if(professionalData) {
        this.saveNewProfessionalConsultancy(professionalData, isEdit, consultancy)
      }
    });
  }

  saveNewProfessionalConsultancy(newProfessional, isEdit: boolean, consultancy?:ITeamProfessional): void {
    const payload = {
      id: isEdit ? consultancy?.id : null,
      consultancyUserId: newProfessional.professionalId,
      role: newProfessional.professionalFunction,
      supConfigurationId: this.supConfigurationId,
      professionalEmail: newProfessional.professionalEmail,
    };

    this.consultancyService.postNewProfessionalStudy(payload).subscribe({
      next: result => {
      if (result) {
        const message = isEdit ? "Profissional atualizado com sucesso" : "Profissional cadastrado com sucesso";
        this.alertService.send(message, "success", true);
      }
      },
      complete: () => this.getTeamConsultancy()
    });
  }

  toggleShowMore(newStatus: boolean): void {
    this.extendedTeamView = newStatus;
  }

  shouldShowRetractedView(): boolean {
    return (
      !this.extendedTeamView &&
      this.isValeView
    )
  }

  getAllConsultancyUsers(): ITeamProfessional[] {
    return this.consultancies.flatMap(consult => {
      return consult.supConfigTeams
    })
  }

  getTeamConsultancy() {
    this.consultancyService.getTeamOfConsultancy(Number(this.activityId)).subscribe({
      next: result => {
        this.consultancies = result;
  
        this.cdr.markForCheck(); // Marca para verificação
        this.cdr.detectChanges(); // Força atualização imediata
      }
    });
  }

  deleteProfessional(userId: number, name: string) {
    this.idToExclude = userId; // Alterado para armazenar o ID
    const modal =this.modal.create<GenericExclusionModalComponent>({
      nzClosable: false,
      nzTitle: '',
      nzWidth: 640,
      nzContent: GenericExclusionModalComponent,
      nzKeyboard: false,
      nzViewContainerRef: this.viewContainerRef,
      nzCentered: true,
      nzMaskClosable: false,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.optionToExclude = name;
    instance.isCancellation = false;
    instance.typeToExclude = 'consultancy-team';
    modal.afterClose.subscribe((professionalData) => {
      if(professionalData) {
        this.userDelete(this.idToExclude)
      }
    })
  }



  copyEmail(): void {
    if (this.emailElement?.nativeElement) {
      const emailText = this.emailElement.nativeElement.innerText.trim();
      navigator.clipboard.writeText(emailText).then(() => {
        this.tooltipMessage = "E-mail copiado!";
        this.tooltipColor = "#00a599"; // Define cor verde
         // Reseta após 3 segundos
            setTimeout(() => {
              this.tooltipMessage = "Copiar e-mail";
              this.tooltipColor = ""; // Retorna à cor padrão do tooltip
            }, 3000);
              this.alertService.send('E-mail copiado com sucesso!', "success", true);
      }).catch(err => {
          this.alertService.send('Erro ao copiar o e-mail', "error", true);
      });
    } else {
      this.alertService.send('emailElement está indefinido.', "error", true);
    }
  }

  // Método para excluir profissional
  userDelete(userId: number): void {


    this.consultancyService.deleteUser(userId).subscribe({
      next: () => {

        this.alertService.send('Profissional excluído com sucesso!', "success", true);

        this.getTeamConsultancy(); // Atualiza a lista ANTES do detectChanges()

        setTimeout(() => {
          this.cdr.markForCheck();
          this.cdr.detectChanges();
        }, 100); // Pequeno delay para garantir renderização
      },
      error: (err) => {
        this.alertService.send('Erro ao excluir o usuário', "error", true);
      },
      complete: () => {}
    });
  }

}
