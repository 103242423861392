<ol class="breadcrumb">
  <li><a [routerLink]="['/'+routesEnum.TASKS]" class="breadcrumb">Início</a></li>
  <li *ngFor="let page of pages | async; let i = index">
    <span class="level" *ngIf="!!pages">> </span>

    <a (click)="toPage(page.url)">
      <span>
        <app-short-text-hint
          [originalText]="page.name ? page.name : page.name==='' ? '...' : page.name"
          [maxChars]="30"
        ></app-short-text-hint>
      </span>
    </a>
  </li>
</ol>
