<div class="app-input-textarea-count sl__form">
    <div class="sl__form__label">
        <span class="sl__form__required" *ngIf="required">*</span>
        <span class="sl__form__title">{{ name }}</span>
    </div>

    <nz-textarea-count [nzMaxCharacterCount]="maxCharacterCount">
        <textarea
            nz-input
            [maxlength]="maxCharacterCount" 
            [style.resize]="'none'" 
            [placeholder]="placeholder"
            [rows]="rows"
            [nzStatus]="status"
            [formControl]="controls"
            (keyup)="onChangeValue($event)">
        </textarea>
    </nz-textarea-count>
</div>