import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LicencingModel } from 'src/app/shared/models/licencsing.model';
import { environment } from 'src/environments/environment';
import {
  ACTIVITIES_BY_ID,
  ACTIVITIES_TITLES_LIST,
  ACTIVITIES_UPDATE_BY_ID,
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  EIA_ACTIVITIES_BY_ID,
  LICENSES_LIST_BY_ORGAN,
  LICENSING_SAVE_EDIT,
  LINKED_PROCESS_DELETE,
  PROCESS_BY_ID,
  PROCESS_DELETE,
  SUB_ACTIVITIES_LIST,
  SUMARIZE_ACTIVITY,
  PROCESS_UPDATE_DEADLINE,
  PROCESS_UPDATE_AUTORIZATIVE_DEADLINE
} from '../../constants/apiEndpointsConstants';

import {
  LicenseActivitiesByIdResponse,
  LicenseActivitiesBody,
  LicenseActivitiesResponse,
  LicenseByProjectIdForLinkResponse,
  LicensingCreationBody,
  LicenseActivitityUpdateBody, ProcessLicensePatchRequest,
  CreateNewActivityRequest,
  SumarizeActivityResponse,
  Licenses
} from '../licensing.interface';

import { LinkedProcessesDTO } from 'src/app/shared/dto/process-list-response.dto';
import { ActivityListFilterTableRequest } from 'src/app/modules/projects-v2/interface/activity';


@Injectable({
  providedIn: 'root',
})
export class LicensingService {

  baseUrl: string = environment.apiUrl;
  constructor(private readonly http: HttpClient) {}

  getLicencing(idProject: number): Observable<LicencingModel> {
    return this.http.get<LicencingModel>(
      `${this.baseUrl}/projects-licensing/${idProject}`
    );
  }
  getLicensingList(idProject: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/projects-licensing/by-project/${idProject}`
    );
  }
  getDownloadFile(idfile: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json',
    };
    return this.http.get<any>(
      `${this.baseUrl}/attachments/download/${idfile}`,
      options
    );
  }

  getMeiosRecebimentoNotificacao(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/returns-means`);
  }

  getActivitysList(processId: number, filterParams?: ActivityListFilterTableRequest): Observable<any> {
    let params: any = {};

    if (filterParams) {
      Object.keys(filterParams).forEach(key => {
        if (filterParams[key] !== undefined && filterParams[key] !== null && filterParams[key] !== '') {
          params[key] = filterParams[key];
        }
      });
    }

    return this.http.get<any>(`${ACTIVITIES_BY_ID}/list/${processId}`, { params });
  }

// == V2 == //

  createProcessLicensing(payload: LicensingCreationBody): Observable<any> {
    return this.http.post<LicensingCreationBody>(`${LICENSING_SAVE_EDIT}`, payload);
  }

  updateProcessLicensing(payload: LicensingCreationBody, processId: number): Observable<any> {
    return this.http.put<LicensingCreationBody>(`${LICENSING_SAVE_EDIT}/${processId}`, payload);
  }

  patchProcessLicensing(payload: ProcessLicensePatchRequest, processId: number): Observable<any> {
    return this.http.patch<LicensingCreationBody>(`${LICENSING_SAVE_EDIT}/${processId}`, payload);
  }

  patchDeadlineLicense(processId: number, payload: any): Observable<any> {
    return this.http.patch<any>(`${PROCESS_UPDATE_DEADLINE}/${processId}`, payload);
  }

  patchAutorizativeDateLicense(processId: number, payload: any): Observable<any> {
    const description = encodeURIComponent(payload['description']);
    return this.http.put<any>(`${LICENSING_SAVE_EDIT}/${processId}/${PROCESS_UPDATE_AUTORIZATIVE_DEADLINE}?newDeadline=${payload['deadlineNew']}&description=${description}&userId=${payload['userId']}`, {});
  }

  deleteProcess(id){
    return this.http.patch<LicensingCreationBody>(`${PROCESS_DELETE}/${id}`, {});
  }

  getLicensesByOrgan(id): Observable<any>{
    return this.http.get<any>(`${LICENSES_LIST_BY_ORGAN}/${id}`);
  }

  createNewActivity(payload: LicenseActivitiesBody): Observable<LicenseActivitiesResponse>{
    return this.http.post<LicenseActivitiesResponse>(`${CREATE_ACTIVITY}`, payload);
  }


  getActivityById(id: number): Observable<LicenseActivitiesByIdResponse> {
    return this.http.get<LicenseActivitiesByIdResponse>(`${ACTIVITIES_BY_ID}/${id}`)
  }

  getTitles(product?){
    if(product){
      return this.http.get<any>(`${ACTIVITIES_TITLES_LIST}?productType=${product}`);
    }
    else {
      return this.http.get<any>(`${ACTIVITIES_TITLES_LIST}`);
    }
  }

  updateProcessLinks(id:number, linkedProcess: LinkedProcessesDTO[] ):Observable<any>{
    return this.http.patch<any>(`${LINKED_PROCESS_DELETE}/${id}`, linkedProcess);
  }

  getLicensesByProjectIdForLink(projectId, type, licenseType): Observable<LicenseByProjectIdForLinkResponse[]>{
    return this.http.get<LicenseByProjectIdForLinkResponse[]>(`${PROCESS_BY_ID}/by-project/${projectId}/${type ? type + '/' : ''}for-link/${licenseType}`);
  }

  getLicenses(projectId): Observable<Licenses[]>{
    return this.http.get<Licenses[]>(`${PROCESS_BY_ID}/${projectId}`);
  }

  getLicensesByProjectIdForLinkByLicenseId(projectId, type, licenseId): Observable<LicenseByProjectIdForLinkResponse[]>{
    return this.http.get<LicenseByProjectIdForLinkResponse[]>(`${PROCESS_BY_ID}/by-project/${projectId}/${type ? type + '/' : ''}for-link-by-id/${licenseId}`);
  }

  getSubActivitiesList(projectLicensingId: number, activityId?: any): Observable<any> {
    if (activityId) {
      return this.http.get<any>(`${SUB_ACTIVITIES_LIST}/${projectLicensingId}?parentId=${activityId}`);
    }
    return this.http.get<any>(`${SUB_ACTIVITIES_LIST}/${projectLicensingId}`);
  }

  deleteActivity(activityId):Observable<any>{
    return this.http.delete(`${DELETE_ACTIVITY}/${activityId}`);
  }

  updateActivityById(id: number, body: LicenseActivitityUpdateBody): Observable<any> {
    return this.http.patch<any>(`${ACTIVITIES_UPDATE_BY_ID}/${id}`, body);
  }

  getEiaStudiesByLicensingProcessList(ids: number[]): Observable<any> {
    let url = EIA_ACTIVITIES_BY_ID + '?';
    ids.forEach(id => {
      if (url.endsWith('?')) {
        url += `projectLicensingIds=${id}`
      } else {
        url += `&projectLicensingIds=${id}`
      }
    })

    return this.http.get<any>(url);
  }

  //REFATORAÇÃO PROCESSOS E ATIVIDADES
  createNewActivityPost(payload: CreateNewActivityRequest): Observable<LicenseActivitiesResponse>{
    return this.http.post<LicenseActivitiesResponse>(`${CREATE_ACTIVITY}`, payload);
  }

  aboutActivityById(id: number): Observable<SumarizeActivityResponse> {
    return this.http.get<SumarizeActivityResponse>(`${SUMARIZE_ACTIVITY}/${id}`);
  }

}
