import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-generic-modal-search',
  templateUrl: './generic-modal-search.component.html',
  styleUrl: './generic-modal-search.component.scss'
})
export class GenericModalSearchComponent implements OnChanges {

  private _cleanValues: boolean = false;

  @Input()
  set cleanValues(value: boolean) {
    if (value !== this._cleanValues) {
      this._cleanValues = value;
      this.resetFilter();
    }
  };

  @Input() searchValue: string = ''

  @Output() changeSearchValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeCleanValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  visible = false;
  documentsAnalysis = [];


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['searchValue'] && changes['searchValue']['currentValue'] !== "") {
      setTimeout(() => {
        this.changeSearchValue.emit(this.searchValue);
      }, 1300)
    }
  }

  resetFilter(): void {
    this.searchValue = '';
    this._cleanValues = false;
    this.changeSearchValue.emit('');
    this.changeCleanValue.emit(true);
  }

  searchFilter(): void {
    this.changeSearchValue.emit(this.searchValue);
  }

  callSendAction(event): void {
    if(event.key === 'Enter'){
      this.searchFilter();
    }
  }
}
