import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  timeOfDay: string = '';
  loggedUser: any;
  userName: string = '';

  ngOnInit(): void {
    this.getUser();
  }

  getGreeting(): void {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      this.timeOfDay = 'greeting.morning';
    } else if (currentHour < 18) {
      this.timeOfDay = 'greeting.afternoon';
    } else {
      this.timeOfDay = 'greeting.evening';
    }

  }

  getUser(): void {
    const userSession = sessionStorage.getItem('user');
    if (userSession) {
      this.loggedUser = JSON.parse(userSession);
      this.userName = `${this.loggedUser.given_name} ${this.loggedUser.family_name}`;
    } else {
      this.loggedUser = null;
      this.userName = '';
    }
    this.getGreeting();
  }
}
