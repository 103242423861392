import { Component, ElementRef, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"]
})
export class ListItemComponent {
  @ViewChild('itemTitle', { static: true }) itemTitleEl: ElementRef;
  @Input() itemTitle: string;
  @Input() itemDescription: string;
  @Input() iconComponent: TemplateRef<any>;
  @Input() iconComponentContext: any;

  tooltipVisible = false;

  checkIfTextExceedsTwoLines() {
    const element = this.itemTitleEl?.nativeElement;

    if (element) {
      const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
      const maxHeight = lineHeight * 2;
      const textHeight = element.scrollHeight;
  
      return textHeight > maxHeight;
    }

    return false;
  }
}