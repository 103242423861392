import { Component, Input, ViewContainerRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ITeam } from 'src/app/modules/suppliers/interface/consultancy-configuration-view';
@Component({
  selector: 'app-study-team-container',
  templateUrl: './study-team-container.component.html',
  styleUrl: './study-team-container.component.scss'
})
export class StudyTeamContainerComponent {

  @Input() valeteam: any[] = []
  @Input() consultancies: ITeam[] = []
  @Input() supConfigurationId: number = 0;
  @Input() consultancyId: number = 0
  @Input() activityId: number = 0
  @Input() isValeView: boolean = false;

  isDisabled: boolean = false

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ){}

}
