import { Component,  inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { MessageService } from 'src/app/shared/common/services/message.service';

@Component({
  selector: 'app-generic-exclusion-modal',
  templateUrl: './generic-exclusion-modal.component.html',
  styleUrls: ['./generic-exclusion-modal.component.scss']
})
export class GenericExclusionModalComponent implements OnInit, OnDestroy {
  
  @Input() optionToExclude: string = ''
  @Input() typeToExclude: string = ''
  @Input() additionalText: string;
  @Input() mainText: string;
  @Input() acceptButtonText: string;
  @Input() isCancellation: boolean = false;
  @Input() titleModal: string = '';

  private readonly destroy$: Subject<any> = new Subject();
  
  readonly #modal = inject(NzModalRef);

  constructor(private messageService: MessageService){}

  ngOnInit(){
    return
  }

  onCancel(): void {
    this.#modal.destroy(null)
    this.#modal.close();
  }

  onClickConfirm(id: any): void {
    this.#modal.destroy(true)
    this.#modal.triggerOk();
    this.#modal.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }


}
