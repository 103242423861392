import { Component,OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TasksListRequestParams } from '../interface/task-paged-response';
import { GenericTableComponent, GenericTableConfiguration, GenericTableHeader } from 'src/app/shared/components/tables/generic-table/generic-table.component';
import { TasksService } from '../service/tasks.service';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { debounceTime, map, Subject, takeUntil } from 'rxjs';
import { Location } from '@angular/common';
import { Pageable, Sort } from 'src/app/shared/dto/communications-list-response.dto';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrl: './my-tasks.component.scss'
})
export class MyTasksComponent implements OnInit, OnDestroy {

  private getMyTasksSubject = new Subject<TasksListRequestParams>();
  private destroy$ = new Subject<void>();

  @ViewChild('templateIconFilter', { static: true }) templateIconFilter!: TemplateRef<any>;
  @ViewChild('templateTasksFilter', { static: true }) templateTasksFilter!: TemplateRef<any>;
  @ViewChild('templateProjectNameFilter', { static: true }) templateProjectFilter!: TemplateRef<any>;
  @ViewChild('templateDeadlineFilter', { static: true }) templateDeadlineFilter!: TemplateRef<any>;
  @ViewChild('statusActivityTemplate', { static: true }) statusActivityTemplate!: TemplateRef<any>;
  @ViewChild(GenericTableComponent) genericTableComponent!: GenericTableComponent;

  configurationTable: GenericTableConfiguration;
  dataHeaderTable: GenericTableHeader[] = [];
  myProjects;
  dataListTable: any = {
    content: [],
    pageable: {} as Pageable,
    size: 0,
    sort: {} as Sort,
    totalElements: 0,
    numberOfElements: 0,
    totalPages: 0
  };

  paramsFilter: TasksListRequestParams = {
    nextDayCount: 7,
    filter: {}
  };

  topologiesOptionsFilter: Array<{label: string, value: number, checked: boolean}> = [];
  projectsInList:  Array<{label: string, value: number, checked: boolean}> = [];
  cleanValuesFilter: boolean = false;
  totalElements: number = 0;
  totalItemsPage: number = 0;
  currentPage: number = 0;
  totalPages: number = 1;
  isLoading: boolean = false;
  isLoadingProjects: boolean = true;

  popOverNotification: boolean = false;
  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  riplarequisition: boolean = false;

  selectableDaysButtons = [
    { label: "Essa semana", value: "7" },
    { label: "Próximos 15 dias", value: "15" },
    { label: "Próximos 30 dias", value: "30" },
    { label: "Próximos 60 dias", value: "60" },
    { label: "Todas ativas", value: "all" },
    { label: "Concluídas recentemente", value: "finished" },
  ]

  selectedFilterButton = "7";

  constructor(
    private readonly location: Location,
    private readonly myTasksService: TasksService,
    private readonly alertMessageService: AlertMessageService,
    private readonly router: Router,
    private readonly projectsV2Service: ProjectsV2Service,
  ) { }

  ngOnInit(): void {
    this.createFilterOptions();
    this.initFilterObject();
    this.initConfigurationTable();
    this.getMyTasks(this.paramsFilter, true)
    this.getMyProjects();
    this.createSubjectFilter();
  }

  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();
  }

  goBack() {
    this.location.back();
  }

  createFilterOptions(): void {

    this.getTipologies().subscribe((response) => {
      this.topologiesOptionsFilter = response.map((topology) => ({
        label: topology.description,
        value: topology.id,
        checked: false
      }));
    });
  }

  initConfigurationTable(): void {
    this.configurationTable = {
      isCheckbox: false,
      isResizeColumn: true,
      isClickRowEvent: false
    }

    this.dataHeaderTable = [
      { field: 'icon', label: '', isCustom: true,template: this.statusActivityTemplate, templateName: this.templateIconFilter },
      { field: 'title', isLink: true, label: 'Tarefa', templateName: this.templateTasksFilter, disableTooltip: true },
      { field: 'projectName', label: 'Projeto', templateName: this.templateProjectFilter },
      { field: 'deadline', label: 'Vencimento', templateName: this.templateDeadlineFilter, disableTooltip: true, behindSchedule: true },
    ]
  }

  selectedFilterValueChanged(filterButtonAction: { value: string, selected: boolean }): void {
    if (this.isLoading) return;

    if (filterButtonAction.value !== this.selectedFilterButton) {
      const nextDayNumberValue = parseInt(filterButtonAction.value);

      if (Number.isNaN(nextDayNumberValue)) {
        this.paramsFilter.filter = {
          status: filterButtonAction.value === "finished" ? 
            ["CON", "APR"] : 
            ["INI", "EAN", "REV", "RAS", "ENT", "REV", "DEV", "REA"] 
        }
        this.paramsFilter.nextDayCount = filterButtonAction.value === "finished" ? 7 : 1000000
      } else {
        delete this.paramsFilter.filter.status
        this.paramsFilter.nextDayCount = nextDayNumberValue
      }

      this.getMyTasks(this.paramsFilter, true)
    }

    this.selectedFilterButton = filterButtonAction.value;
  }

  getIconName(data: any): string {
    const path = "../../../assets/icons/";
    if (data.status === "CON" || data.status === "APR") return path + "clock_green_icon.svg";

    const value = data.deadline;
    const today = moment().startOf('day');
    const date = moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD'], true)

    console.log(date, today)
    if (date.isValid() && !date.isSameOrAfter(today)) {
      return path + "clock_red_icon.svg";
    } else {
      return path + "clock_yellow_icon.svg"
    }
  }

  initFilterObject(): void {
    this.paramsFilter = {
      nextDayCount: 7,
      filter: {}
    }
  }

  onChangeValueTable(event: any, type: string): void {
    if (type === 'templateProjectNameFilter') this.paramsFilter.filter.projectId = event;
    if (type === 'templateTasksFilter') {
      if (event.length > 0) {
        this.paramsFilter.filter.title = event;
      } else {
        this.paramsFilter.filter.title = undefined;
      }
    }

    if (type === 'templateDeadlineFilter') {
      if(event.date && event.period) {
        this.paramsFilter.filter.deadlineStart = event.date;
        this.paramsFilter.filter.deadlineEnd = event.period;
      }
    }

    this.resetDataListContent();
    this.getMyTasks(this.paramsFilter, true);
  }

  onCleanFilter(event, eventType) {
    if (event) {
      if (eventType === 'templateProjectNameFilter') this.paramsFilter.filter.projectId = undefined;
      if (eventType === 'templateTasksFilter') this.paramsFilter.filter.title = undefined;
      if (eventType === 'templateDeadlineFilter') {
        this.paramsFilter.filter.deadlineEnd = undefined;
        this.paramsFilter.filter.deadlineStart = undefined;
      }

      this.topologiesOptionsFilter.forEach((topology) => topology.checked = false);
      this.cleanValuesFilter = true;
    }
  }

  createSubjectFilter() {
    this.getMyTasksSubject.pipe(debounceTime(500), takeUntil(this.destroy$))
    .subscribe(paramsFilter => this.getMyTasks(paramsFilter));
  }

  getMyProjects() {
    this.isLoadingProjects = true;

    this.myTasksService.getProjects().subscribe({
      next: (response) => {
        this.isLoadingProjects = false;
        this.myProjects = response;
      },
      error: () => {
        this.isLoadingProjects = false;
        this.myProjects = []
      }
    })
  }

  getMyTasks(paramsFilter: TasksListRequestParams, reload: boolean = false) {
    if ((this.currentPage >= this.totalPages || this.isLoading) && !reload) return;
    this.isLoading = true;

    this.myTasksService.getTasks(paramsFilter, this.currentPage ).subscribe({
      next: (response) => {
        if (response) {
          if (reload) {
            this.dataListTable.content = response.content;
          } else {
            this.currentPage++;
            this.dataListTable.content = [...this.dataListTable.content, ...response.content];
          }
          this.setProjectsInList();
          this.dataListTable.content.forEach(item => {
            item.linkToAccess = item.taskLink
          })
          this.totalElements = response.totalElements;
          this.totalItemsPage =  this.dataListTable.content.length;
          this.totalPages = response.totalPages;
          this.isLoading = false;
        }
      },
      complete: () => {
        this.cleanValuesFilter = false;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.alertMessageService.send(`${err.error.errors.details}`, 'error');
      }
    });
  }

  setProjectsInList() {
    this.projectsInList = this.dataListTable.content.map(task => ({
      label: task.projectName,
      value: task.projectId,
      checked: false
    })).filter((o, index, arr) =>
      arr.findIndex(item => JSON.stringify(item) === JSON.stringify(o)) === index
    );
  }

  redirectToProject(projectId: number): void {
    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID_V2, {projectId: projectId});
    this.router.navigate([`/${RoutesEnum.PROJECTS}${routeProject}`]);
  }

  resetDataListContent() {
    this.dataListTable.content = [];
    this.currentPage = 0;
    this.totalPages = 1;
  }

  private getTipologies(){
    return this.projectsV2Service.getTopologies().pipe(
      map(response => {
        return response;
      })
    );
  }

  goToTasks(data: any){
    // ROTA DA TASK
  }
}
