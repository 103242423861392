import { Component,  inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { ConsultingConfigurationServices } from '../../../service/consultancy-configuration.services';
import { ITeamProfessional } from '../../../interface/consultancy-configuration-view';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';

@Component({
  selector: 'app-add-team-modal',
  templateUrl: './add-team-modal.component.html',
  styleUrls: ['./add-team-modal.component.scss']
})
export class AddTeamModalComponent implements OnInit, OnDestroy {

  @Input() consultancyId: any
  @Input() alreadyAddedMembers: string[];
  @Input() consultancy: ITeamProfessional;

  formAddTeam: FormGroup;

  hasIncompleteForm: boolean = false

  private readonly destroy$: Subject<any> = new Subject();

  readonly #modal = inject(NzModalRef);

  professionalList: any[] = [];

  blockInput: boolean = true;

  constructor(
    private readonly messageService: AlertMessageService,
    private readonly formBuilder: FormBuilder,
    private readonly consultancyService: ConsultingConfigurationServices
  ){}

  ngOnInit(){
    this.initForm()
    this.getUserConsultancy(this.consultancyId);
  }

  getUserConsultancy(id){
    this.consultancyService.getResponsibleUserByConsultancyId(id, false).subscribe({
      next: result => {
        if (this.consultancy) {
          this.professionalList = result['content'];
          this.consultancy.profissionalId = this.professionalList
            .find(professional => professional.email === this.consultancy?.userEmail)?.id || null;
        } else {
          this.professionalList = result['content'].filter(user => (
            !this.alreadyAddedMembers.includes(user.email)
          ));
        }

        this.formAddTeam.setValue({
          professional: this.consultancy?.profissionalId ?? null,
          professionalFunction: this.consultancy?.role || null,
          professionalEmail: this.consultancy?.userEmail || null
        });

        this.blockInput = !!this.consultancy;
      },
      error: erro => {
        this.messageService.send('Erro ao carregar api de profissionais.', "error", true);
      }
    })
  }

  initForm() {
    this.formAddTeam = this.formBuilder.group({
      professional: [null, [Validators.required]],
      professionalFunction: [null, [Validators.required]],
      professionalEmail: [null, [Validators.required, Validators.email]]
    });
  }


  onCancel(): void {
    this.#modal.destroy(null)
    this.#modal.close();
  }

  onClickConfirm() {
    if(this.formAddTeam.invalid){
      this.hasIncompleteForm = true
      this.formAddTeam.markAllAsTouched();
      setTimeout(() => {
        this.hasIncompleteForm = false
      }, 3500);
    } else {

      //CHAMAR API PARA CADASTRAR NOVO INTEGRANTE DO TIME
      const newProfessionalTeam = {
        professionalId: this.formAddTeam.controls['professional'].value,
        professionalFunction: this.formAddTeam.controls['professionalFunction'].value,
        professionalEmail: this.formAddTeam.controls['professionalEmail'].value
      }
      this.#modal.destroy(newProfessionalTeam)
      this.#modal.triggerOk();
      this.#modal.close();
    }
  }

  isFieldInvalid(field: string): boolean {
    return this.formAddTeam.get(field)?.invalid && this.formAddTeam.get(field)?.touched ? true : false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  closeAlert() {
    this.hasIncompleteForm = false
  }

}
