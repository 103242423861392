<div class="form-content">
  <form nz-form nzLayout="vertical" [formGroup]="form" id="communicationForm">
    <h2>Sobre o projeto</h2>
    <div nz-row [nzGutter]="[15, 15]">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="email" nzRequired>Nome do Projeto</nz-form-label>
          <nz-form-control [ngClass]="projectDisabled ? 'select-border_disabled' : 'select-border'">
            <nz-select
              id="projectIdSelect"
              nzBorderless
              nzPlaceHolder="Selecione o projeto"
              formControlName="projectId"
              [compareWith]="compareFnString"
              (ngModelChange)="getProcessByProjectAndCommunications()"
              [nzDisabled]="projectDisabled"
            >
              <nz-option *ngFor="let project of projects" nzValue="{{ project.id }}" nzLabel="{{ project.name }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <br />
      </div>
    </div>
    <h2 class="mb-10">Sobre o processo</h2>
    <div nz-row [nzGutter]="[15, 15]">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="email" nzRequired >Processo vinculado</nz-form-label>
          <nz-form-control [ngClass]="projectLicenseReadonly ? 'select-border_disabled' : 'select-border'">
            <nz-select
              id="projectLicensingIdSelect"
              nzBorderless
              nzPlaceHolder="Selecione o processo"
              formControlName="projectLicensingId"
              (ngModelChange)="showProcessData()"
              [nzDisabled]="projectLicenseReadonly"
            >
              <nz-option *ngFor="let process of processesList" nzValue="{{ process.id }}" nzLabel="{{ process.title }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="email" >Número do processo</nz-form-label>
          <nz-form-control [ngClass]="!processEnable ? 'select-border_disabled' : 'select-border'">
            <input
              nz-input
              placeholder="Número do processo"
              type="text"
              nzBorderless
              formControlName="processNumber"
              (change)="updateSummaryProcessNumber()"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="[15, 15]">
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label nzFor="activityTree" >Atividades vinculadas</nz-form-label>
          <nz-form-control class="select-border-tags">
            <nz-tree-select
              class="treeSelect"
              nzPlaceHolder="Selecione atividades vinculadas"
              [nzMaxTagCount]="6"
              [nzMaxTagPlaceholder]="omittedPlaceHolder"
              [nzVirtualHeight]="'150px'"
              [nzNodes]="activities"
              [nzDefaultExpandAll]="true"
              [nzAllowClear]="false"
              [nzMultiple]="true"
              id="activityTree"
              formControlName="activitiesIds"
              (focusout)="updateSummaryActivities()"
            ></nz-tree-select>
            <ng-template #omittedPlaceHolder let-omittedValues>
              e {{ omittedValues.length }} mais...
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <br />
      </div>
    </div>
    <h2>Sobre a comunicação</h2>
    <div nz-row [nzGutter]="[15, 15]">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="communicationTypeId" nzRequired >Tipo de comunicação</nz-form-label>
          <nz-form-control [ngClass]="comTypeReadonly ? 'select-border_disabled' : 'select-border'">
            <nz-select
              nzBorderless
              nzPlaceHolder="Selecione o tipo de comunicação"
              formControlName="communicationTypeId"
              id="communicationTypeIdSelect"
              (ngModelChange)="enableAboutLicenseForm()"
              [nzDisabled]="comTypeReadonly"
              [compareWith]="compareFnString"
              (focusOut)="updateSummaryCommunicationType()"
            >
              <nz-option *ngFor="let type of communicationTypes" nzValue="{{ type.id + '' }}" nzLabel="{{ type.description }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="title" nzRequired >Título da comunicação</nz-form-label>
          <nz-form-control class="select-border">
            <input
              nz-input
              placeholder="Título da comunicação"
              type="text"
              nzBorderless
              formControlName="title"
              id="title"
              maxlength="200"
              (focusout)="updateSummaryTitleFocusLost()"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="[15, 15]">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="responsibleUserId" nzRequired>
            Responsável pelo {{ type === "REC" ? "recebimento" : "envio" }}
          </nz-form-label>
          <nz-form-control class="select-border">
            <nz-select
              nzBorderless
              [nzPlaceHolder]="'Selecione o responsável pelo ' + (type === 'REC' ? 'recebimento' : 'envio')"
              formControlName="responsibleUserId"
              id="responsibleUserIdSelect"
              (ngModelChange)="updateSummaryResponsibleUser()"
            >
              <nz-option *ngFor="let user of users" nzValue="{{ user.id }}" nzLabel="{{ user.name }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="identificationNumber" nzRequired>Identificador da correspondência</nz-form-label>
          <nz-form-control class="select-border">
            <input
              nz-input
              placeholder="Nº Identificador da correspondência"
              type="text"
              nzBorderless
              formControlName="identificationNumber"
              id="identificationNumber"
              maxlength="50"
              (focusout)="updateSummaryIdentificationNumber()"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="[15, 15]">
      <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-label nzFor="protocolNumber">Número do protocolo</nz-form-label>
          <nz-form-control class="select-border">
            <input
              nz-input
              placeholder="Número do protocolo"
              type="text"
              nzBorderless
              formControlName="protocolNumber"
              id="protocolNumber"
              (focusout)="updateSummaryProtocolNumber()"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-label nzFor="registerNumber">Nº Registro Eletrônico (Ex.: SEI)</nz-form-label>
          <nz-form-control class="select-border">
            <input
              nz-input
              placeholder="Nº Registro Eletrônico (Ex.: SEI)"
              type="text"
              nzBorderless
              formControlName="registerNumber"
              id="registerNumber"
              (focusout)="updateSummaryRegisterNumber()"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-label nzFor="otherIdentificationNumber">Outro número de identificação</nz-form-label>
          <nz-form-control class="select-border">
            <input
              nz-input
              placeholder="Outro número de identificação"
              type="text"
              nzBorderless
              formControlName="otherIdentificationNumber"
              id="otherIdentificationNumber"
              (focusout)="updateSummaryOtherIdentificationNumber()"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="[15, 15]">
      <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-label nzFor="returnMeanId" nzRequired>Meio de {{ type === "REC" ? "recebimento" : "envio" }}</nz-form-label>
          <nz-form-control class="select-border">
            <nz-select
              nzBorderless
              nzPlaceHolder="Selecione o meio de recebimento"
              formControlName="returnMeanId"
              id="returnMeanIdSelect"
              (ngModelChange)="updateSummaryReturnMean()"
            >
              <nz-option *ngFor="let returnMean of returnMeans" nzValue="{{ returnMean.value }}" nzLabel="{{ returnMean.label }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-label nzFor="date" nzRequired >Data de {{ type === "REC" ? "recebimento" : "envio" }}</nz-form-label>
          <nz-form-control class="select-border">
            <nz-date-picker
              id="licenseReceivedDate"
              nzFormat="dd/MM/YYYY"
              nzBorderless
              style="width: 100%"
              formControlName="date"
              [nzSuffixIcon]="''"
              (ngModelChange)="calcDealineDate()"
              (focusout)="updateSummaryDate()"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-label nzFor="organResponse" nzRequired>
            {{
              this.type === "REC"
                ? "A resposta do empreendedor é exigida pelo órgão?"
                : "A resposta do órgão precisa ser monitorada?"
            }}
          </nz-form-label>
          <nz-form-control>
            <nz-radio-group
              formControlName="organResponse"
              id="organResponse"
              (ngModelChange)="updateDeadlineDaysValidators()"
            >
              <label nz-radio [nzValue]="true">Sim</label>
              <label nz-radio [nzValue]="false">Não</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="[15, 15]" *ngIf="this.form?.value?.organResponse">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="deadlineDays" nzRequired>Prazo para resposta (dias corridos)</nz-form-label>
          <nz-form-control class="select-border">
            <input
              nz-input
              placeholder="Prazo para resposta(dias corridos) "
              type="number"
              appPositiveNumber
              nzBorderless
              formControlName="deadlineDays"
              id="deadlineDays"
              [(ngModel)]="deadline"
              (ngModelChange)="calcDealineDate()"
              (input)="calcDealineDate()"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label>Data limite para resposta</nz-form-label>
          <nz-form-control class="select-border">
            <input
              nz-input
              placeholder="Data limite para resposta"
              disabled="disabled"
              type="text"
              nzBorderless
              [value]="deadLineResponseDate"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div
      nz-row
      [nzGutter]="[15, 15]"
      *ngIf="type === 'REC' || complementaryResponseSent === true"
    >
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label
            nzFor="connectionsIds"
            [nzRequired]="complementaryResponseSent === true"
            >Comunicações vinculadas</nz-form-label
          >
          <nz-form-control class="select-border-tags">
            <nz-select
              nzMode="tags"
              nzPlaceHolder="Comunicações vinculadas"
              nzBorderless
              formControlName="connectionsIds"
              id="connectionsIdsSelect"
              (ngModelChange)="updateSummaryConnections()"
            >
              <nz-option *ngFor="let communication of communicationsList" [nzLabel]="communication.title" [nzValue]="communication.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <br />
      </div>
    </div>
    <div nz-row [nzGutter]="[15, 15]">
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label nzFor="description" nzRequired>Descrição</nz-form-label>
          <nz-form-control class="select-border" id="communicationDesc">
            <textarea
              rows="4"
              nz-input
              nzBorderless
              placeholder="Descrição"
              formControlName="description"
              id="description"
              (focusout)="updateSummaryDescription()"
            ></textarea>
          </nz-form-control>
        </nz-form-item>
        <br />
      </div>
    </div>
    <br />
    <ng-container *ngIf="enableAboutLicense || displayLicenseData">
      <h2>{{"projects.communication.about-authorization-act"  | translate }}</h2>
      <div nz-row [nzGutter]="[15, 15]">
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzFor="licenseNumber" nzRequired>Número do documento</nz-form-label>
            <nz-form-control class="select-border">
              <input
                nz-input
                placeholder="Número do documento"
                type="text"
                maxlength="50"
                nzBorderless
                formControlName="licenseNumber"
                id="licenseNumber"
                (focusout)="updateSummaryLicenseNumber()"
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzFor="existsObligation" nzRequired>Existem obrigações atreladas?</nz-form-label>
            <nz-form-control>
              <nz-radio-group
                formControlName="existsObligation"
                id="existsObligation"
                (ngModelChange)="updateSummaryExistsObligation()"
              >
                <label nz-radio [nzValue]="true">Sim</label>
                <label nz-radio [nzValue]="false">Não</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row [nzGutter]="[15, 15]">
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label nzFor="licenseEmissionDate" nzRequired
              >Data de emissão</nz-form-label
            >
            <nz-form-control class="select-border">
              <nz-date-picker
                id="licenseEmissionDate"
                nzFormat="dd/MM/YYYY"
                nzBorderless
                style="width: 100%"
                formControlName="licenseEmissionDate"
                [nzSuffixIcon]="''"
                (focusout)="updateSummaryLicenseEmissionDate()"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label nzFor="licenseExpirationDate" nzRequired
              >Data de validade</nz-form-label
            >
            <nz-form-control class="select-border">
              <nz-date-picker
                id="licenseValidDate"
                nzFormat="dd/MM/YYYY"
                nzBorderless
                style="width: 100%"
                [nzSuffixIcon]="''"
                formControlName="licenseExpirationDate"
                (focusout)="updateSummaryLicenseExpirationDate()"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label nzFor="renewalDeadline" nzRequired
              >Prazo de renovação(em dias)</nz-form-label
            >
            <nz-form-control class="select-border">
              <input
                nz-input
                placeholder="Prazo de renovação(em dias)"
                type="number"
                mask="000"
                nzBorderless
                formControlName="renewalDeadline"
                id="renewalDeadline"
                (focusout)="updateSummaryRenewalDeadline()"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>
    <br />
    <h2>Anexos</h2>
    <div nz-row [nzGutter]="[15, 15]">
      <!--Upload-->
      <div nz-col [nzSpan]="24">
        <div
          nz-col
          nzXs="24"
          nzSm="24"
          nzMd="24"
          nzLg="24"
          nzXl="24"
          class="form-col"
        >
          <nz-upload
            id="btnUpArquivoV2"
            nzType="drag"
            [nzMultiple]="true"
            [nzBeforeUpload]="beforeUpload"
            [(nzFileList)]="fileList"
            [nzRemove]="removeFiles"
            nzListType="picture"
            [nzDownload]="downloadFile"
            class="uploadProjectFiles"
          >
            <p class="ant-upload-drag-icon" #uploadInput>
              <img src="assets/icons/upload-v2-icon.svg" alt="upload" />
            </p>
            <p class="ant-upload-hint">
              <span class="text-upload">{{
                "projects.assessment.drag-drop" | translate
              }}</span>
            </p>
          </nz-upload>
        </div>
      </div>
    </div>
  </form>
</div>
