<nz-layout class="layout">
  @if(!isUserConsultancy){
    <nz-sider nzBreakpoint="md" nzWidth="260px" nzCollapsed="false" [nzCollapsedWidth]="72" nzCollapsible
      (nzCollapsedChange)="forceHideText()" [ngClass]="{ 'collapsed': !menuFull }" class="sl-sider">
      <app-sidebar [expanded]="menuFull"></app-sidebar>
    </nz-sider>
  }

  <nz-layout>
    <nz-header>
      @if(!isUserConsultancy){
        <app-topbar></app-topbar>
      } @else{
        <app-topbar-consultancy></app-topbar-consultancy>
      }
    </nz-header>
    <nz-content class="inner-content">
      <app-alert-message></app-alert-message>
      <div>
        @if(!isUserConsultancy){
          <app-breadcrumb></app-breadcrumb>
        }
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <app-chat></app-chat>
  </nz-layout>
</nz-layout>
