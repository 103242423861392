<div class="container licensing-process-container">
  <form nz-form [formGroup]="form" *ngIf="!loading" class="sl__form">
    <div nz-row class="mb-20">
      <div nz-col nzSpan="14" class="header">
        <a (click)="goBack()" class="mr-8" id="goBack">
          <img src="/assets/icons/icon_arrow-back.svg" alt="Voltar">
        </a>
        <h1 class="h1-title" id="projectName">
          {{ 'projects.licensing-process.title-page' | translate }}
        </h1>
      </div>
      <div *ngIf="cadProcessLicensing" nz-col nzSpan="10" class="action-box">
        <!-- NOVOS BOTOES PRIME-NG -->
        <p-button
          id="btnCancelar"
          label="Cancelar"
          [text]="true"
          class="p-button-cancelar"
          (click)="cancelProcess()"
        />
        <button
          id="btnSalvarProcesso"
          pButton
          label="Salvar processo"
          [ngClass]="canContinue ? 'p-button-salvar' : 'p-button-salvar-disabled'"
          [disabled]="!canContinue"
          (click)="createProcess()">
        </button>

      </div>
    </div>

    <div nz-row class="rounded-row">
      <div nz-col nzSpan="24" class="organAndProcess">

        <div id="dropdownSelectTypeProcess">
          <h2 class="card--title_h2">{{ 'projects.licensing-process.subtitle-page-process' | translate }}</h2>

          <div id="id_organ_typeProcess" class="select_organ_typeProcess">
            <div class="select_organ">
              <app-select-search
                [listOfOption]="competentOrgans"
                [required]="true"
                [showClear]="false"
                [disabled]="isOrganDisabled"
                [optionLabel]="'Selecione o órgão'"
                [setManualValue]="selectedOrgan"
                (changeValue)="onSelectOrgan($event)"
                ></app-select-search>
              </div>
              <div class="select_typeProcess">
                <app-select-search
                  [listOfOption]="listOfTypeProcess"
                  [required]="true"
                  [showClear]="false"
                  [disabled]="isDisabled"
                  [optionLabel]="'projects.licensing-process.type-authorizing-act' | translate"
                  [clearData]="clearData"
                  [setManualValue]="selectedLicenseType"
                  (changeValue)="onSelectProcessType($event)"
                ></app-select-search>
            </div>

          </div>
        </div>

        <div id="inputTitleProcess">
          <h2 class="card--title_h2_process_name">{{ 'projects.licensing-process.subtitle-page-identify' | translate }}</h2>
          <h2 class="card--title_h3">{{ 'projects.licensing-process.title-licensing-process' | translate }}</h2>

          <!-- TITULO DO PROCESSO -->
          <div class="sl__form__label">
            <span class="sl__form__required fontSize12">*</span>
            <span class="sl__form__title fontSize12">Título do processo</span>
          </div>
          <input
            pInputText
            type="text"
            [ngClass]="statusProcessName ? 'inputField' : 'inputField disabled' "
            formControlName="processName"
            placeholder="Informe..."
            maxlength="200"
            (change)="onInputChange($event)"
            (keydown)="onKeyDown($event)"
            (keyup)="onSelectProcessName()"
            />
        </div>

        <div nz-row class="authorizativeDate">

          <div class="alignColumn">
            <h2 class="card--title_h2_process_name">{{ 'projects.licensing-process.title-authorizative-date' | translate }}</h2>
            <h2 class="card-authorizativeDate--title_h3">{{ 'projects.licensing-process.title-authorizative-date-subtitle' | translate }}</h2>
          </div>

          <div class="alignRow">
            <div nz-col nzSpan="8">
              <h2 class="card-authorizativeDate--title_h3-date fontSize12">{{ 'projects.licensing-process.title-authorizative-date' | translate }}</h2>
              <nz-date-picker
                class="formatDatePicker"
                nzBorderless
                nzMode="date"
                nzFormat="dd/MM/yyyy"
                nzPlaceHolder="Selecione"
                [nzAllowClear]="true"
                [nzSuffixIcon]="'calendar'"
                [(ngModel)]="authorizativeDateModel"
                formControlName="authorizativeDate"
                (ngModelChange)="setAuthorizativeDate()">
              </nz-date-picker>
            </div>
            <div nz-col nzSpan="16">
              <div class="alignFlex">
                <span class="sl__form__required fontSize12 red" *ngIf="hasAuthorizativeData">*</span>
                <h2 class="card-authorizativeDate--title_h3-date fontSize12">{{ 'projects.licensing-process.title-authorizative-date-reason' | translate }}</h2>
              </div>
              <textarea
                nz-input
                [maxlength]="1000"
                class="mt-8"
                [placeholder]="'Informe qual a estratégia a ser cumprida'"
                [rows]="6"
                [disabled]="!hasAuthorizativeData"
                [required]="hasAuthorizativeData"
                formControlName="authorizativeDateComments"
                [(ngModel)]="authorizativeDateCommentsModel"
                (ngModelChange)="setAuthorizativeDateComments()">
              </textarea>
            </div>
          </div>

        </div>
        
      </div>
    </div>

  </form>
  <div class="rounded-row spinner-container" *ngIf="loading">
    <nz-spin nzSimple id="spinner"></nz-spin>
  </div>
</div>
