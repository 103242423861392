import { CONFIGURATION_CONSULTANCIES_DEACTIVATE, CONFIGURATION_CONSULTANCIES_REACTIVATE, REMOVE_SUPPLIERS_CONSULTANCY } from './../../projects/constants/apiEndpointsConstants';
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { catchError, map, Observable, tap, throwError } from "rxjs";
import {
  ACTIVITY_DELIVERABLE_DASHBOARD,
  CONSULTANCY_API, CONSULTANCY_CONFIG__SUPPLIER_RELEASED, CONSULTANCY_USERS_API, CONFIGURATION_STUDY_NEW, CONFIGURATION_STUDY,
  GET_ACTIVITIES_DELIVERABLES, GET_MY_STUDIES_CONSULTANCY,
  baseUrl,
  CONFIGURATION_PROFESSIONAL_STUDY_NEW,
  GET_TEAM_CONSULTANCY,
  GET_TEAM_VALE,
  REMOVE_PROFESSIONAL_API,
  CONFIGURATION_STUDY_TECHPROP} from "../../projects/constants/apiEndpointsConstants";
import { ActivityDeliverableResponse, ConsultancyConfigSupplierReleaseResponse } from "../../projects-v2/interface/activity";
import { Consultancy, ConfigurationStudy, IProfessionalResponse, NewConfigurationStudy, ITeam } from "../interface/consultancy-configuration-view";
import { ConsultancyDeliverablesListRequestParams, GetConfigurationUserTableResponse } from "../../configuration/interface/configuration-user-view";

@Injectable({
  providedIn: 'root'
})
export class ConsultingConfigurationServices {
  constructor(private readonly http: HttpClient) {
  }

  getMyStudies(): Observable<any[]> {
    return this.http.get<any[]>(`${GET_MY_STUDIES_CONSULTANCY}`);
  }


  getConsultancyList(): Observable<Consultancy[]> {
    return this.http.get<Consultancy[]>(`${CONSULTANCY_API}`);
  }

  getResponsibleUserByConsultancyId(consultancyId: number, onlyResponsible:boolean = true): Observable<Consultancy[]> {
    return this.http.get<Consultancy[]>(`${CONSULTANCY_USERS_API}=${consultancyId}&onlyResponsible=${onlyResponsible}`);
  }

  getUsersByConsultancyId(consultancyId: number, name: string = ""): Observable<Consultancy[]> {
    return this.http.get<Consultancy[]>(`${CONSULTANCY_USERS_API}=${consultancyId}&onlyResponsible=false&name=${name}`);
  }

  getConfigSupplierReleased(activityId: number): Observable<ConsultancyConfigSupplierReleaseResponse> {
    return this.http.get<ConsultancyConfigSupplierReleaseResponse>(`${CONSULTANCY_CONFIG__SUPPLIER_RELEASED}/${activityId}`);
  }

  getActivityDeliverableDashboard(activityId: number): Observable<ActivityDeliverableResponse> {
    return this.http.get<ActivityDeliverableResponse>(`${ACTIVITY_DELIVERABLE_DASHBOARD}/${activityId}`);
  }

  postNewConfigurationStudy(params): Observable<NewConfigurationStudy> {
    return this.http.post<NewConfigurationStudy>(CONFIGURATION_STUDY_NEW, params)
  }

  putConfigurationStudy(params):Observable<NewConfigurationStudy> {
    return this.http.put<NewConfigurationStudy>(CONFIGURATION_STUDY_NEW, params)
  }

  getConfigurationStudy(activityId): Observable<any>{
    return this.http.get<any>(`${CONFIGURATION_STUDY}/${activityId}`);
  }
  
  getConfigurationStudyWithResponse(activityId): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${CONFIGURATION_STUDY}/${activityId}`, { observe: 'response' });
  
  }
  getConfigurationStudyConsultancy(activityId): Observable<any>{
    return this.http.get<any>(`${CONFIGURATION_STUDY_TECHPROP}/${activityId}`);
  }

  getTeamOfVale(activityId): Observable<ITeam[]>{
    return this.http.get<ITeam[]>(`${GET_TEAM_VALE}?activityId=${activityId}`);
  }
  
  deleteEquipeTeam(id): Observable<void> {
    return this.http.delete<void>(`${GET_TEAM_VALE}/${id}`);
  }
  
  deleteTechnicalProposal(id): Observable<void> {
    return this.http.delete<void>(`${CONFIGURATION_STUDY_TECHPROP}/${id}`);
  }

  deleteConsultancies(id): Observable<ConfigurationStudy> {
    return this.http.delete<ConfigurationStudy>(`${REMOVE_SUPPLIERS_CONSULTANCY}/${id}`);
  }
  
  deactivateConsultancies(id): Observable<ConfigurationStudy> {
    return this.http.patch<ConfigurationStudy>(`${CONFIGURATION_CONSULTANCIES_DEACTIVATE(id)}`, null);
  }
  
  activateConsultancies(id): Observable<ConfigurationStudy> {
    return this.http.patch<ConfigurationStudy>(`${CONFIGURATION_CONSULTANCIES_REACTIVATE(id)}`, null);
  }

  getTeamOfConsultancy(activityId): Observable<ITeam[]>{
    return this.http.get<ITeam[]>(`${GET_TEAM_CONSULTANCY}?activityId=${activityId}`);
  }

  getConfigurationConsultancies(activityId: number): Observable<any> {
    return this.http.get<any>(`${CONFIGURATION_STUDY}/${activityId}/consultancy`);
  }

  patchDeliverStudyToConsultancy(configurationId: number, type:string): Observable<ConfigurationStudy> {
    return this.http.patch<ConfigurationStudy>(`${CONFIGURATION_STUDY_NEW}/${configurationId}/${type}`, null);
  }

  getActivityDeliverables(activityId: number, filterParams?: ConsultancyDeliverablesListRequestParams, page: number = 0): Observable<GetConfigurationUserTableResponse> {
    let params: any = {};
    let sort = 'name';
    if (filterParams) {
      Object.keys(filterParams).forEach(key => {
        if (filterParams[key] !== undefined && filterParams[key] !== null && filterParams[key] !== '') {
          params[key] = filterParams[key];
        }
      });
    }

    return this.http.get<GetConfigurationUserTableResponse>(`${GET_ACTIVITIES_DELIVERABLES}/${activityId}/deliveries?page=${page}&size=50&sort=${sort}`, { params });
  }

  getOnlyMyActivityDeliverables(activityId: number, filterParams?: ConsultancyDeliverablesListRequestParams, page: number = 0): Observable<GetConfigurationUserTableResponse> {
    let params: any = {};
    let sort = 'name';
    if (filterParams) {
      Object.keys(filterParams).forEach(key => {
        if (filterParams[key] !== undefined && filterParams[key] !== null && filterParams[key] !== '') {
          params[key] = filterParams[key];
        }
      });
    }

    return this.http.get<GetConfigurationUserTableResponse>(`${GET_ACTIVITIES_DELIVERABLES}/${activityId}/deliveries?page=${page}&size=20&userConsultancyOnly=true&sort=${sort}`, { params });
  }

  getProfissionalsByRoleAndComplex(complexId: number, page: number = 0): Observable<IProfessionalResponse> {
    return this.http.get<IProfessionalResponse>(`${baseUrl}/users/page?roles=ROLE_LICENCIAMENTO&roles=ROLE_ESPECIALISTA&complexId=${complexId}&page=${page}&size=10`);
  }

  postNewProfessionalStudy(params): Observable<any> {
    return this.http.post<any>(CONFIGURATION_PROFESSIONAL_STUDY_NEW, params)
  }

  // Método para deletar um usuário
  deleteUser(id: number): Observable<void> {
    return this.http.delete(`${REMOVE_PROFESSIONAL_API}/${id}`, { responseType: 'text' }).pipe(
      tap(response => console.log('🔍 Resposta da API:', response)),
      map(() => undefined), // Força a emissão de um valor para o `next`
      catchError(err => {
        console.error('❌ Erro ao deletar usuário:', err);
        return throwError(() => err);
      })
    );
  }



}
