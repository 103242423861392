import {NgModule} from '@angular/core';
import {RouterModule, type Routes} from '@angular/router';

import {NlpComponent} from "./modules/nlp/nlp.component";
import {DocumentsComponent} from './modules/documents/documents.component';
import {DashboardsComponent} from './modules/dashboards/dashboards.component';
import {NlpReportComponent} from "./modules/nlp/nlp-report/nlp-report.component";
import {NlpWelcomeComponent} from './modules/nlp/nlp-welcome/nlp-welcome.component';
import {ProductsListComponent} from './modules/nlp/products-list/products-list.component';
import {ProjectV2SuspensionComponent} from './modules/projects-v2/project-v2-suspension/project-v2-suspension.component';
import {NlpReferenceTermComponent} from "./modules/nlp/nlp-reference-term/nlp-reference-term.component";
import {NlpAnalysisResultComponent} from "./modules/nlp/nlp-analysis-result/nlp-analysis-result.component";
import {HelpCenterComponent} from "./modules/help-center/help-center.component";
import {AuthGuard} from "./core/auth/guards/auth.guard";
import {LicensingProcessComponent} from './modules/projects-v2/licensing-process/licensing-process.component';
import {RoutesEnum} from './shared/enum/routes.enum';
import {RoutesModulesExternalsEnum} from './shared/enum/routes-modules-externals';
import {TasksComponent} from './modules/tasks/tasks.component';


const routes: Routes = [
  { path: '', redirectTo: `/${RoutesEnum.TASKS}`, pathMatch: 'full' },
  {
    path: RoutesEnum.DASHBOARDS_TYPE, canActivate: [AuthGuard],
    component: DashboardsComponent,
  },
  {
    path: RoutesEnum.TASKS, canActivate: [AuthGuard],
    component: TasksComponent,
  },
  {
    path: RoutesEnum.SUSPENSION, canActivate: [AuthGuard],
    component: ProjectV2SuspensionComponent
  },
  {
    path: RoutesEnum.CANCELLATION, canActivate: [AuthGuard],
    component: ProjectV2SuspensionComponent
  },
  {
    path: 'licensing-process/:projectId',  canActivate: [AuthGuard],
    component: LicensingProcessComponent,
  },
  {
    path: RoutesEnum.NLP, canActivate: [AuthGuard],
    component: NlpComponent,
    children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full', canActivateChild: [AuthGuard] },
      {
        path: 'welcome',
        canActivateChild: [AuthGuard],
        component: NlpWelcomeComponent,
      },
      {
        path: 'termo-referencia',
        canActivateChild: [AuthGuard],
        component: NlpReferenceTermComponent,
      },
      {
        path: 'analise-resultados',
        canActivateChild: [AuthGuard],
        component: NlpAnalysisResultComponent,
      },
      {
        path: 'relatorio',
        canActivateChild: [AuthGuard],
        component: NlpReportComponent,
      },
      {
        path: 'list',
        canActivateChild: [AuthGuard],
        component: ProductsListComponent,
      },
    ],
  },
  {
    path: RoutesEnum.DOCUMENTS, canActivate: [AuthGuard],
    component: DocumentsComponent,
    children: [{ path: '', redirectTo: RoutesEnum.DOCUMENTS, pathMatch: 'full' }],
  },
  {
    path: RoutesEnum.PROJECT, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/projects-v2/projects.module').then((m) => m.ProjectsModule),
  },
  {
    path: RoutesEnum.COMMUNICATION, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/communications/communications.module').then((m) => m.CommunicationsModule),
  },
  {
    path: RoutesEnum.SCOPE_CHANGE, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/scope-change/scope-change.module').then((module) => module.ScopeChangeModule)
  },
  {
    path: RoutesEnum.SMART_DOCS, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/smart-docs/smart-docs.module').then((module) => module.SmartDocsModule)
  },
  {
    path: RoutesEnum.PROJECT, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/suppliers/suppliers.module').then((module) => module.SuppliersModule)
  },
  {
    path: RoutesEnum.CONFIGURATION, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/configuration/configuration.module').then((module) => module.ConfigurationModule),
  },
  {
    path: RoutesEnum.REQUISITIONS, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/requisitions/requisitions.module').then((module) => module.RequisitionsModule),
  },
  {
    path: RoutesEnum.CHAT_AI, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/chat-ia/chat-ia.module').then((module) => module.ChatIAModule),
  },
  {
    path: RoutesEnum.TECHNICAL_ANALYSIS, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/technical-analysis/technical-analysis.module').then((module) => module.TechnicalAnalysisModule),
  },
  {
    path: RoutesEnum.AUTHORIZING_ACTS, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/authorizing-acts/authorizing-acts.module').then((module) => module.AuthorizingActsModule),
  },
  {
    path: RoutesEnum.ENVIROMENTAL_STUDYS, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/enviromental-studys/enviromental-studys.module').then((module) => module.EnviromentalStudysModule),
  },
  {
    path: RoutesEnum.HELP_CENTER, canActivate: [AuthGuard],
    component: HelpCenterComponent,
  },
  {
    path: RoutesModulesExternalsEnum.STUDIES,
    loadChildren: () => import('./modules-externals/consultancy/consultancy.module').then((module) => module.ConsultancyModule),
  },
  {
    path: '**', redirectTo: `/${RoutesEnum.TASKS}`, pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule { }
