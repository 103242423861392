import {Component, Input} from '@angular/core';
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";

@Component({
  selector: 'app-user-badge',
  standalone: true,
  imports: [
    NzTooltipDirective
  ],
  templateUrl: './user-badge.component.html',
  styleUrl: './user-badge.component.scss'
})
export class UserBadgeComponent {
  @Input() color: string = '#000';
  @Input() additionalStyles: any = {};
  @Input() userName: string = '';
  @Input() showFullValue: boolean = false;
  get initials(): string {
    if (this.showFullValue) return this.userName;

    return this.userName ? this.userName.split(' ').map(name => name[0]).join('').substring(0, 2).toUpperCase() : '-';
  }
}
