import { Component, EventEmitter, Input, Output, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import moment from 'moment';

/*
* isClickRowEvent: Habilita a função para emitir valor quando clicar na linha da tabela
*/

export interface GenericTableConfiguration {
  isCheckbox?: boolean;
  isResizeColumn?: boolean;
  isClickRowEvent?: boolean;
  isHoverRowEvent?: boolean;
  isEditableFirstColumn?: boolean;
  isDisableRow?: { field: string };
}

/*
* field: Nome do campo que será utilizado para renderizar o conteúdo na coluna
* subField: Nome do campo que será utilizado para renderizar o conteúdo na coluna, caso o campo seja um objeto
* templateName: Nome do template que será utilizado para renderizar o conteúdo de filtro na coluna
*
* Exemplo: (field + subField)
*  user: [{id: 1, name: 'abc', email: 'abc@email.com'}]
*  field: 'user', subField: 'name'
*/
export interface GenericTableHeader {
  field: string;
  subField?: string;
  label: string;
  templateName?: TemplateRef<any>;
  templateIcon?: TemplateRef<any>;
  fieldTemplateIcon?: string;
  isLink?: boolean;
  eventClick?: boolean;
  isBadge?: boolean;
  isCustomTooltipDescription?: boolean;
  disableTooltip?: boolean;
  behindSchedule?: boolean;
  isColor?: boolean;
  isEditable?: boolean;
  isCustom?: boolean;
  template?: TemplateRef<any>;
}

// Remover após a criação da tabela com grid editável | components: (HistoricChatsComponents)
export interface InputEditableConfig {
  id: any,
  targetField: string,
  editable: boolean
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrl: './generic-table.component.scss',
})
export class GenericTableComponent implements AfterViewInit, OnDestroy {

  @Input() dataConfiguration: GenericTableConfiguration = { isCheckbox: false, isResizeColumn: false, isClickRowEvent: false, isEditableFirstColumn: false };
  @Input() dataHeaders: GenericTableHeader[] = [];
  @Input() dataList: any[] = [];
  @Input() resetSelection: boolean = false;
  @Input() isLoading: boolean = true;
  @Input() statusOptions: { label: string, description: string }[] = [];
  @Input() editableConfiguration: InputEditableConfig = { id: null, targetField: '', editable: false };
  @Input() scrollSelector: string = '.p-datatable-wrapper';
  @Input() parentContext: any;
  @Input() linkToAccess: string = '';

  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeValueCell: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeHoverValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeValueCol: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMore: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeEditRow: EventEmitter<any> = new EventEmitter<any>();

  selectedDataValues: any;

  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.detectScrollEvent();
    });
  }

  ngOnDestroy(): void {
    const datatableWrapper = document.querySelector(this.scrollSelector);
    datatableWrapper?.removeEventListener('scroll', this.detectScrollEvent);
  }

  detectScrollEvent(): void {
    const datatableWrapper = document.querySelector(this.scrollSelector);
    if (datatableWrapper) {
      datatableWrapper.addEventListener('scroll', (event) => {
        const scrollTop = datatableWrapper.scrollTop;
        const clientHeight = datatableWrapper.clientHeight;
        const scrollHeight = datatableWrapper.scrollHeight;
        const tolerance = 2;
        if (scrollTop + clientHeight >= scrollHeight - tolerance) this.loadMore.emit();
      });
    }
  }

  clearSelection(): void {
    this.selectedDataValues = [];
  }

  resolveField(rowData: any, isCustom: boolean | undefined, field: string, subField?: string): any {
    if (!rowData || !field || isCustom) return null;

    const value = field.split('.').reduce((acc, key) => acc?.[key], rowData);

    if (Array.isArray(value)) {
      if (subField && typeof value[0] === 'object') {
        return value
          .map(item => item[subField] || JSON.stringify(item))
          .sort((a, b) => a.localeCompare(b))
          .join(', ');
      }
      return value.join(', ');
    }

    if (typeof value === 'object' && value !== null) {
      if (subField) return value[subField] !== undefined ? value[subField] : JSON.stringify(value);
      return JSON.stringify(value);
    }
    return (value !== undefined || value !== null) ? this.formatDate(value) : null;
  }

  resolveFieldTemplateIcon(rowData: any, fieldTemplateIcon: any): string {
    return rowData[fieldTemplateIcon];
  }

  formatDate(value: any): string | null {
    if (!value) return null;
    if (value instanceof Date) {
      const date = moment(value);
      return date.isValid() ? date.format('DD/MM/YYYY') : null;
    }
    const date = moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD', 'DD/MM', 'MM/YYYY', moment.ISO_8601], true);
    if (date.isValid()) {
      return date.format('DD/MM/YYYY');
    }
    return value;
  }
  formatToMultiline(value: string | null | undefined, isCustomTooltipDescription: any): string[] | null {
    if (!value) return null;

    return value.split(',').map(item => {
      const trimmedItem = item.trim();
      return isCustomTooltipDescription ? this.getStatusDescription(trimmedItem) || trimmedItem : trimmedItem;
    }).sort((a, b) => a.localeCompare(b));
  }

  getStatusDescription(statusCode: string): string | null {
    const status = this.statusOptions.find(option => option.label === statusCode);
    return status ? status.description : null;
  }

  isDateBehindSchedule(rowData: any, field: string): boolean {
    const colum = this.dataHeaders.find(header => header.behindSchedule === true);
    if (!colum) return false;
    if (colum.field === field) {

      const value = rowData[colum.field];
      const today = moment().startOf('day');
      const date = moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD'], true)

      return date.isValid() && !date.isSameOrAfter(today) && rowData.status !== 'CON';
    } else {
      return false;
    }

  }

  onChangeSelect(): void {
    this.changeValue.emit(this.selectedDataValues);
  }

  /**
   * Método chamado quando uma linha da tabela é clicada.
   *
   * @param rowData - Dados da linha que foi clicada.
   *
   * Se a configuração de dados permitir eventos de clique na linha (`isClickRowEvent`),
   * emite o valor da linha clicada através do evento `changeValue`.
   */
  onRowClick(rowData: any): void {
    if (!this.dataConfiguration.isClickRowEvent) return;
    this.changeValue.emit(rowData);
  }

  /**
   * Método chamado quando uma célula da tabela é clicada.
   *
   * @param rowData - Dados da linha correspondente à célula clicada.
   * @param isLink - Indica se a célula clicada é um link. Se for falso, a função retorna imediatamente.
   *
   * Se `isLink` for verdadeiro, emite um evento `changeValueCell` com os dados da linha.
   */
  onCellClick(rowData: any, isLink?: boolean): void {
    if (!isLink) return;
    this.changeValueCell.emit(rowData);
  }

  /**
   * Método chamado quando o cursor do mouse passa sobre uma linha da tabela.
   *
   * @param rowData - Dados da linha que está sendo sobrevoada.
   *
   * Se a configuração de dados (`dataConfiguration`) tiver o evento de clique na linha ativado (`isClickRowEvent`),
   * emite o valor da linha sobrevoada através do evento `changeHoverValue`.
   */
  onRowHover(rowData: any) {
    if (!this.dataConfiguration.isHoverRowEvent) return ;
    this.changeHoverValue.emit(rowData);
  }

  /**
   * Método chamado quando uma coluna é clicada.
   *
   * @param rowData - Dados da linha que foi clicada.
   * @param event - Evento de clique do mouse.
   *
   * Adiciona o evento de clique aos dados da linha e emite um evento para notificar a mudança de valor na coluna.
   */
  onColClick(rowData: any, event: MouseEvent): void {
    rowData.click = event
    this.changeValueCol.emit(rowData);
  }

  onEventClick(rowData: any, event: MouseEvent): void {
    rowData.click = event
    this.eventClick.emit(rowData);
  }

  onChangeEditRow(rowData, event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === 'Escape') {
      this.changeEditRow.emit(rowData);
    }
  }

  onInputFocusOut(rowData): void {
    this.changeEditRow.emit(rowData);
  }

  isSelected(item: any): boolean {
    return this.selectedDataValues && this.selectedDataValues.length > 0 ? this.selectedDataValues.includes(item) : false;
  }

  getColorForColumn(colorArray: { color: string; column: string }[] | undefined, column: string): string {
    if (!colorArray) return '';
    const colorObject = colorArray.find(colorObj => colorObj.column === column);
    return colorObject ? colorObject.color : '';
  }

  checkIfIsCustomItem(item) {
    return item.isCustom && this.parentContext;
  }

}
