<div class="app-modal-prediction-conclusion">
    <i class="pi pi-filter ft__size12"
        [class.has__value]="_dateInput || _periodInput || behindSchedule || pending || withoutProtocol || closeToRenovation"
        (click)="modalDataPrediction.toggle($event)"></i>

    <p-overlayPanel #modalDataPrediction>
        <div class="filter__content__prediction-conclusion">

            <div class="content__input__prediction-conclusion">
                <label class="input__label__prediction-conclusion"> Por data </label>
                <div class="input__calendar__prediction-conclusion">
                    <p-calendar
                        [(ngModel)]="_dateInput"
                        [iconDisplay]="'input'"
                        [showIcon]="true"
                        placeholder="Selecione a data"
                        (ngModelChange)="updateDate(); updateWithoutProtocol()">
                    </p-calendar>
                </div>
            </div>

            <div class="content__input__prediction-conclusion">
                <label class="input__label__prediction-conclusion"> Por período </label>
                <div class="input__calendar__prediction-conclusion">
                    <p-calendar
                        [selectionMode]="'range'"
                        [minDate]="minDate"
                        [(ngModel)]="_periodInput"
                        [iconDisplay]="'input'"
                        placeholder="Selecione o período"
                        [showIcon]="true"
                        (ngModelChange)="updatePeriod(); updateWithoutProtocol()">
                    </p-calendar>
                </div>
            </div>


            <div class="options-checkbox">
                <ng-container *ngIf="hasCloseToRenovation">
                    <label nz-checkbox [(ngModel)]="closeToRenovation">Próximo da renovação</label><br>
                </ng-container>
                <ng-container *ngIf="hasBehindSchedule">
                    <label nz-checkbox [(ngModel)]="behindSchedule">Atrasados</label><br>
                </ng-container>
                <ng-container *ngIf="hasPending">
                    <label nz-checkbox [(ngModel)]="pending">Pendentes</label><br>
                </ng-container>
                <ng-container *ngIf="hasWithoutProtocol">
                    <label nz-checkbox [(ngModel)]="withoutProtocol" (ngModelChange)="updatePeriod(); updateDate()">Não protocolado</label><br>
                </ng-container>
            </div>

            <div class="buttons__prediction-conclusion">
                <app-button-icon-secondary
                    customClassBtn="padding: 0 20px; float: left;"
                    [id]="'btn-icon'"
                    text="Limpar filtro"
                    altText="Limpar filtro"
                    (click)="onClean()">
                </app-button-icon-secondary>

                <app-button-icon-primary
                    customClassBtn="padding: 0 20px; float: right;"
                    [id]="'btn-icon'"
                    text="Aplicar filtro"
                    altText="Aplicar filtro"
                    (click)="onApplyFilter()">
                </app-button-icon-primary>

            </div>

        </div>
    </p-overlayPanel>

</div>
