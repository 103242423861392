import { Component, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AddTeamModalComponent } from 'src/app/modules/suppliers/modal/consultancy-configuration/delivery-study-modal-add-team/add-team-modal.component';
import { ConsultingConfigurationServices } from 'src/app/modules/suppliers/service/consultancy-configuration.services';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';

@Component({
  selector: 'app-team-vale',
  templateUrl: './team-vale.component.html',
  styleUrl: './team-vale.component.scss'
})
export class TeamValeComponent {

  @Input() valeteam: any[] = []
  @Input() supConfigurationId: number
  @Input() consultancyId: number
  @Input() activityId: any

  @ViewChild('emailElement') emailElement!: ElementRef

  isDisabled: boolean = false
  tooltipMessage: string = "Copiar e-mail";
  tooltipColor: string = "";

  constructor(
    private readonly modal: NzModalService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly consultancyService: ConsultingConfigurationServices,
    private readonly alertService: AlertMessageService
  ){}

  addNewTeamVale() {
    const modal =this.modal.create<AddTeamModalComponent>({
      nzClosable: false,
      nzTitle: '',
      nzWidth: 640,
      nzContent: AddTeamModalComponent,
      nzKeyboard: false,
      nzViewContainerRef: this.viewContainerRef,
      nzCentered: true,
      nzMaskClosable: false,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.consultancyId = this.consultancyId;
    modal.afterClose.subscribe((professionalData) => {
      if(professionalData) {
        this.saveNewProfessionalTeamVale(professionalData)
      }
    })   
  }

  saveNewProfessionalTeamVale(newProfessional){
    const payload = {
      consultancyUserId: newProfessional.professionalId,
      role: newProfessional.professionalFunction,
      supConfigurationId: this.supConfigurationId,
      professionalEmail: newProfessional.professionalEmail,
    }
    this.consultancyService.postNewProfessionalStudy(payload).subscribe({
      next: result => {
        if (result) {
          this.alertService.send("Profissional cadastrado com sucesso", "success")
        }
      }
    })
  }

  copyEmail(): void {
    if (this.emailElement?.nativeElement) {
      const emailText = this.emailElement.nativeElement.innerText.trim();
      navigator.clipboard.writeText(emailText).then(() => {
        this.tooltipMessage = "E-mail copiado!";
        this.tooltipColor = "#00a599"; // Define cor verde
         // Reseta após 3 segundos
            setTimeout(() => {
              this.tooltipMessage = "Copiar e-mail";
              this.tooltipColor = ""; // Retorna à cor padrão do tooltip
            }, 3000);
              this.alertService.send('E-mail copiado com sucesso!', "success", true);
      }).catch(err => {
          this.alertService.send('Erro ao copiar o e-mail', "error", true);
      });
    } else {
      this.alertService.send('emailElement está indefinido.', "error", true);
    }
  }

}
