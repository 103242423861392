<!-- DADOS CONSULTORIA -->
<div class="app-study-team-consultancy">
    <header>
        <h1 class="title">{{ "suppliers.study-team.consultancy.title" | translate }}</h1>
    </header>
    <div class="consultancy">
        @if (consultancies.length === 0 && !isValeView) {
            <p-button
                id="btnNewTeam"
                [text]="true"
                label="{{ 'suppliers.study-team.consultancy.btn-add-new-team' | translate }}"
                icon="pi pi-plus"
                iconPos="left"
                [ngClass]="'colorGreen'"
                (click)="addNewTeamConsultancy()"
                onkeypress="addNewTeamConsultancy()"/>
        }
        
        @if (!this.shouldShowRetractedView()) {
            <div *ngFor="let consultancy of consultancies">
                <h1 class="subtitle">{{ consultancy.name }}</h1>
            
                <p-button
                    *ngIf="!isValeView"
                    id="btnNewTeam"
                    [text]="true"
                    label="{{ 'suppliers.study-team.consultancy.btn-add-new-team' | translate }}"
                    icon="pi pi-plus"
                    iconPos="left"
                    [ngClass]="'colorGreen'"
                    (click)="addNewTeamConsultancy()"
                    onkeypress="addNewTeamConsultancy()"/>
    
            
                <ng-container [ngTemplateOutlet]="openListOfConsultancyUsers" 
                    [ngTemplateOutletContext]="{ consultancy: consultancy }"> 
                </ng-container>

            </div>
            <p-button
                *ngIf="isValeView"
                id="btnShowMore"
                [text]="true"
                label="Mostrar menos"
                icon="pi pi-chevron-up"
                iconPos="left"
                size="small"
                [ngClass]="'colorGreen'"
                (click)="toggleShowMore(false)"
                onkeypress="toggleShowMore(false)"/>
        } @else {
            <ng-container [ngTemplateOutlet]="closedListOfConsultancyUsers" 
                [ngTemplateOutletContext]="{ consultancyUsers: getAllConsultancyUsers() }"> 
            </ng-container>

            <p-button
                id="btnShowMore"
                [text]="true"
                label="Mostrar mais"
                icon="pi pi-chevron-down"
                iconPos="left"
                size="small"
                [ngClass]="'colorGreen'"
                (click)="toggleShowMore(true)"
                onkeypress="toggleShowMore(true)"/>
        }
    </div>
</div>
<!-- FIM DADOS CONSULTORIA -->

<ng-template #openListOfConsultancyUsers let-consultancy='consultancy'>
    <div class="caixaGeral"  *ngFor="let consultancyUser of consultancy.supConfigTeams">
        <h1 class="responsibleLabel mt-16">{{ consultancyUser.role }}</h1>

        <div class="responsible">
            <app-user-badge [userName]="consultancyUser.userName" [color]="'#2078A9'"></app-user-badge>
            <h1 class="responsibleName">{{ consultancyUser.userName }}</h1>
        </div>

        <div class="caixaDosIcones">
            <h1 class="responsibleEmail" #emailElement>{{ consultancyUser.userEmail}}</h1>
            <div class="menuIconOptions mt-8">
                <div class="icon"
                    nzTooltipColor="#00a599" 
                    nz-tooltip="{{ tooltipMessage }}"
                    [nzTooltipColor]="tooltipColor"
                    nzTooltipTrigger="hover"
                    nzTooltipClassName="custom-tooltip"
                    (click)="copyEmail()"
                    onkeypress="copyEmail()">
                      <img src="../../../../../../../assets/icons/team-copy-email-icon.svg" alt="Copy Email" />
                </div>
                <div
                    *ngIf="!isValeView && loggedUser?.email !== consultancyUser.userEmail"
                    class="icon"
                    (click)="addNewTeamConsultancy(consultancyUser, true)"
                    onkeypress="addNewTeamConsultancy(consultancyUser, true)">
                    <img src="../../../../../../../assets/icons/team-edit-email-icon.svg" alt="Editar" />
                </div>
                <div
                    *ngIf="!isValeView && loggedUser?.email !== consultancyUser.userEmail"
                    class="icon"
                    (click)="deleteProfessional(consultancy.id, consultancy.userName)"
                    onkeypress="deleteProfessional(consultancy.id, consultancy.userName)"
                    nz-tooltip="Excluir profissional"
                    nzTooltipTrigger="hover">
                    <img src="../../../../../../../assets/icons/team-delete-email-icon.svg" alt="Excluir" />
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #closedListOfConsultancyUsers let-consultancyUsers='consultancyUsers'>
    <section class="users_grid">
        <div *ngFor="let consultancyUser of consultancyUsers?.slice(0, 8); index as i">
            <app-user-badge *ngIf="i < 7" [userName]="consultancyUser.userName" [color]="'#2078A9'"></app-user-badge>
            <app-user-badge *ngIf="i === 7" 
                [showFullValue]="true"
                [additionalStyles]="{ backgroundColor: '#007E7A', color: '#FFFFFF' }"
                [userName]="'+' + (consultancyUsers.length - 7).toString()" color="#FFFFFF"></app-user-badge>
        </div>
    </section>
</ng-template>
