<div class="app-study-team">
    <ng-container *ngIf="isValeView" [ngTemplateOutlet]="valeStudyTeamView" ></ng-container>
    <ng-container *ngIf="!isValeView" [ngTemplateOutlet]="consultancyStudyTeamView" ></ng-container>
</div>

<!-- Visão do time do estudo por parte da consultoria -->
<ng-template #consultancyStudyTeamView>
    <app-team-consultancy
        [supConfigurationId]="supConfigurationId"
        [consultancyId]="consultancyId"
        [activityId]="activityId"
        [isValeView]="false"
    ></app-team-consultancy>

    <nz-divider></nz-divider>
    
    <app-team-vale
        [supConfigurationId]="supConfigurationId"
        [consultancyId]="consultancyId"
        [valeteam]="valeteam"
        [activityId]="activityId"
    ></app-team-vale>
</ng-template>


<!-- Visão do time do estudo por parte da vale -->
<ng-template #valeStudyTeamView>
    <app-team-vale
        [supConfigurationId]="supConfigurationId"
        [consultancyId]="consultancyId"
        [valeteam]="valeteam"
        [activityId]="activityId"
    ></app-team-vale>

    <nz-divider></nz-divider>

    <app-team-consultancy
        [supConfigurationId]="supConfigurationId"
        [consultancyId]="consultancyId"
        [activityId]="activityId"
        [isValeView]="true"
    ></app-team-consultancy>
</ng-template>