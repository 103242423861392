<div class="app-add-team-modal">
    <div class="content">
        <div class="alignCenter">
            <div class="content__img">
                <img class="plus__ico" src="/assets/icons/icon_caution_circle_warning_yellow.svg" alt="" />
            </div>
            <h2 class="title">
              {{ blockInput ? 'Editar Participante' : ('suppliers.study-team.modal-add-team.title' | translate) }}
            </h2>
        </div>

        <!-- ALERTA -->
        <nz-alert
            *ngIf="hasIncompleteForm"
            id="alert_information"
            nzType="error"
            nzCloseable
            (nzOnClose)="closeAlert()"
            nzDescription="{{ 'suppliers.study-team.modal-add-team.error-message' | translate}}"
            [nzIcon]="customIconTemplate" nzShowIcon>
        </nz-alert>
        <!-- ICONE CUSTOMIZADO -->
        <ng-template #customIconTemplate>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.5C7.58172 4.5 4 8.08172 4 12.5C4 16.9183 7.58172 20.5 12 20.5C16.4183 20.5 20 16.9183 20 12.5C20 8.08172 16.4183 4.5 12 4.5ZM2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5Z" fill="#D11212"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7071 9.79289C17.0976 10.1834 17.0976 10.8166 16.7071 11.2071L11.7071 16.2071C11.3166 16.5976 10.6834 16.5976 10.2929 16.2071L7.29289 13.2071C6.90237 12.8166 6.90237 12.1834 7.29289 11.7929C7.68342 11.4024 8.31658 11.4024 8.70711 11.7929L11 14.0858L15.2929 9.79289C15.6834 9.40237 16.3166 9.40237 16.7071 9.79289Z" fill="#D11212"/>
            </svg>
        </ng-template>
        <!-- FIM ICONE CUSTOMIZADO -->

        <div class="content__text">
            <p style="font-weight: 700; margin-top: 8px;" [innerHTML]="'suppliers.study-team.modal-add-team.subtitle' | translate"></p>
        </div>

        <form [formGroup]="formAddTeam" class="mt-4">

            <nz-form-item class="sl-form-item">
                <div class="textarea-detail">
                    <span class="red">*</span>
                    <span>{{ 'suppliers.study-team.modal-add-team.profissional' | translate }}</span>
                    <nz-form-control [nzSm]="24" [nzXs]="24" class="inputField">
                        <nz-select  id="professionalSelect"
                                    formControlName="professional"
                                    #selectProfessional
                                    nzShowSearch
                                    [class]="isFieldInvalid('professional') ? 'invalid' : null"
                                    [nzAllowClear]="this.formAddTeam.controls['professional'].value ? 'true' : 'false'"
                                    [nzSize]="'large'"
                                    [nzNotFoundContent]="renderTemplateNotFound"
                                    nzPlaceHolder="Selecione"
                                    [nzDisabled]="blockInput">
                            <nz-option
                                *ngFor="let item of professionalList"
                                [nzLabel]="item.name"
                                [nzValue]="item.id">
                            </nz-option>
                        </nz-select>
                        <small class="small-text" *ngIf="isFieldInvalid('professional')">Selecione o profissional que fará parte da equipe</small>

                        <ng-template #renderTemplateNotFound>
                          <div class="content__not__found">
                            <span>Não encontrou a pessoa? Peça que ela solicite acesso via C0 ao Smart Licensing.</span>
                          </div>
                      </ng-template>
                    </nz-form-control>
                </div>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control [nzSm]="24" [nzXs]="24" class="inputField">
                    <div class="textarea-detail">
                        <span class="red">*</span>
                        <span>{{ 'suppliers.study-team.modal-add-team.profissional-paper' | translate }}</span>
                        <input id="professionalFunctionSelect" [class]="isFieldInvalid('professionalFunction') ? 'inputTxt invalidField' : 'inputTxt'" formControlName="professionalFunction" nz-input placeholder="{{ 'suppliers.study-team.modal-add-team.placeholder-profissional-paper' | translate }}" />
                        <small class="small-text" *ngIf="isFieldInvalid('professionalFunction')">Informe qual papel esse profissional vai desempenhar na equipe deste estudo.</small>
                    </div>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control [nzSm]="24" [nzXs]="24" class="inputField">
                    <div class="textarea-detail">
                        <span class="red">*</span>
                        <span>{{ 'suppliers.study-team.modal-add-team.email' | translate }}</span>
                        <input id="professionalEmailSelect" [class]="isFieldInvalid('professionalEmail') ? 'inputTxt invalidField' : 'inputTxt'" formControlName="professionalEmail" nz-input placeholder="{{ 'suppliers.study-team.modal-add-team.placeholder-email' | translate }}" />
                        <small class="small-text" *ngIf="isFieldInvalid('professionalEmail')">Informe o email válido, com mesmo domínio da empresa, para recebimento de notificações.</small>
                    </div>
                </nz-form-control>
            </nz-form-item>
          </form>

        <div class="content__buttons">
            <app-button-icon-secondary
                customClassBtn="padding: 0 20px; margin-right: 15px; outline: none"
                [id]="'btn-icon-secondary'"
                [text]="'Cancelar'"
                altText="Cancelar"
                (click)="onCancel()">
            </app-button-icon-secondary>

            <app-button-icon-primary
                customClassBtn="padding: 0 20px; outline: none"
                [id]="'btn-icon'"
                [text]="'Salvar'"
                altText="Salvar"
                (click)="onClickConfirm()">
            </app-button-icon-primary>
        </div>

    </div>
</div>
