<div class="app-tasks sl__simpleLayout">
  <div class="outer__wrapper tasks_container">
    <!-- BODY -->
    <div nz-row [nzGutter]="[16, 16]">
      <div class="my-tasks">
        <app-svg-icon icon="icon_EIAvsTR" [current]="true" fill="#007e7a" [size]="64"></app-svg-icon>
        <div class="tasks-info">
          <h2> {{'tasks-module.my-tasks-view.title' | translate}} </h2>
          <div class="info__table"> {{'tasks-module.my-tasks-view.sub-title' | translate}} </div>
        </div>
      </div>
      <div nz-col [nzSpan]="24">
        <div class="container__days_filter">
          <app-selectable-filter-button 
            *ngFor="let button of selectableDaysButtons"
            [buttonText]="button.label"
            [value]="button.value"
            [shouldUpdatedStateAutomatically]="false"
            [selected]="selectedFilterButton === button.value"
            (buttonClicked)="selectedFilterValueChanged($event)"
          ></app-selectable-filter-button>
        </div>

        <div class="info__table">
          <div class="count__info">
            {{ 'tasks-module.my-tasks-view.show-itens-count-pt1' | translate }} <strong>{{ totalItemsPage }}</strong>
            {{ 'tasks-module.my-tasks-view.show-itens-count-pt2' | translate }} <strong>{{ totalElements }}</strong>.
          </div>
        </div>

        <div class="container__table">
          <nz-spin [nzSpinning]="isLoading" id="spinner">
            <app-generic-table
              [dataConfiguration]="configurationTable"
              [dataHeaders]="dataHeaderTable"
              [isLoading]="isLoading"
              [dataList]="dataListTable?.content ? dataListTable.content : []"
              (loadMore)="getMyTasks(paramsFilter)"
              (changeValueCol)="goToTasks($event)"
              [parentContext]="this"
              >

              <!-- Tipologia -->
              <ng-template #templateTasksFilter>
                <app-generic-modal-search
                  [cleanValues]="cleanValuesFilter"
                  (changeSearchValue)="onChangeValueTable($event, 'templateTasksFilter')"
                  (changeCleanValue)="onCleanFilter($event, 'templateTasksFilter')">
                </app-generic-modal-search>
              </ng-template>

              <!-- ProjectFilter -->
              <ng-template #templateProjectNameFilter>
                <app-modal-filter-checkbox
                  *ngIf="dataListTable.content.length > 0"
                  [filterTableOptions]="projectsInList"
                  [configuration]="{isScroll: true}"
                  (changeSearchValue)="onChangeValueTable($event, 'templateProjectNameFilter')"
                  (changeCleanValue)="onCleanFilter($event, 'templateProjectNameFilter')">
                </app-modal-filter-checkbox>
              </ng-template>

              <!-- Prazo para analise -->
              <ng-template #templateDeadlineFilter>
                <app-modal-prediction-conclusion
                  [cleanValues]="cleanValuesFilter"
                  (changeApplyFilter)="onChangeValueTable($event, 'templateDeadlineFilter')"
                  (changeCleanValues)="onCleanFilter($event, 'templateDeadlineFilter')">
                </app-modal-prediction-conclusion>
              </ng-template>
            </app-generic-table>
          </nz-spin>
        </div>
      </div>
    </div>
  </div>

  <div class="outer__wrapper projects_section">
    <div class="my-tasks">
      <img [src]="'../../../assets/icons/icon_projects.svg'" alt="Ícone de seção de projetos" class="icon"/>
      <div class="projects-info">
        <h2> {{'tasks-module.my-projects-view.title' | translate}} </h2>
        <div class="info__table"> {{'tasks-module.my-projects-view.sub-title' | translate}} </div>
      </div>
    </div>

    <nz-spin [nzSpinning]="isLoadingProjects" id="spinner" [ngClass]="myProjects?.length > 0 ? 'container' : 'empty_container'">
      <div *ngIf="myProjects?.length === 0" class="no_projects_container">
        <img [src]="'../../../assets/icons/icon_projects_cross.svg'" alt="Ícone de lista de projetos vazia" class="icon"/>
        <span>{{'tasks-module.my-projects-view.no-project' | translate}}</span>
      </div>

      <div class="projects_container" *ngFor="let project of myProjects">
        <app-list-item 
          [itemTitle]="project.name"
          [itemDescription]="project.number"
          [iconComponent]="typologyIcon"
          [iconComponentContext]="{ typologyName: project.typologyName }"
          (click)="redirectToProject(project.id)"
          onkeypress="redirectToProject(project.id)"
        >
        </app-list-item>
      </div>
    </nz-spin>
  </div>

</div>

<ng-template #typologyIcon let-typologyName="typologyName">
  <app-typology-icon [typologyName]="typologyName"></app-typology-icon>
</ng-template>

<ng-template #statusActivityTemplate let-data let-parent="parent">
  <img [src]="parent.getIconName(data)" alt="Ícone de lista de projetos vazia" />
</ng-template>