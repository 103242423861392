import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-selectable-filter-button",
  templateUrl: "./selectable-filter-button.component.html",
  styleUrls: ["./selectable-filter-button.component.scss"]
})
export class SelectableFilterButton implements OnChanges {
  @Output() buttonClicked = new EventEmitter<{
    value: string,
    selected: boolean
  }>();

  @Input() buttonText!: string;
  @Input() selected: boolean = false;
  @Input() value: string;
  @Input() shouldUpdatedStateAutomatically: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.selected = changes['selected'].currentValue;
    }
  }

  toggleSelection() {
    if (this.shouldUpdatedStateAutomatically) this.selected = !this.selected;

    this.buttonClicked.emit({
      value: this.value,
      selected: this.selected
    }); 
  }
}