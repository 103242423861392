import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ProjectsV2Service } from "../../../shared/services/projects-v2.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UtilService } from "../../../shared/util.service";
import { Store } from '@ngrx/store';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProjectByIdResponseDto } from 'src/app/shared/dto/project-by-id-response.dto';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CompetentOrgan } from 'src/app/shared/models/competent-organ.model';
import { CompetentOrganService } from 'src/app/shared/services/competent-organ.service';
import { ProcessData } from 'src/app/shared/models/licencsing.model';
import { LicensingService } from '../../projects/projects-licensing/service/licensing.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { UserModel } from 'src/app/shared/models/user.model';
import { ModalCancelProcessComponent } from '../components/licensing-process/modal-cancel-process/modal-cancel-process.component';

import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';
import moment from 'moment';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';

@Component({
  selector: 'app-licensing-process',
  templateUrl: './licensing-process.component.html',
  styleUrls: ['./licensing-process.component.scss'],
})
export class LicensingProcessComponent implements OnInit {

  loading: boolean = true;

  projectId: number;
  project: ProjectByIdResponseDto;
  form!: UntypedFormGroup;

  customStyle = {
    background: 'white',
    'font-size': '12px',
  };

  windowWidth: number = 0;

  competentOrgans: CompetentOrgan[] = [];
  listOfTypeProcess: ProcessData[] = [];

  isDisabled: boolean = true
  isOrganDisabled: boolean = false;

  idSelectedOrgan: number = 0;
  idSelectedTypeProcess: number = 0;
  selectedNameTypeProcess: string = '';

  cadProcessLicensing: boolean = false;
  statusProcessName: boolean = false;
  canContinue: boolean = false;

  processName: string = '';
  users: UserModel[] = [];
  user: any;
  gestor = false;

  loadingProject: boolean = false;

  selectOrgan: any;
  selectTypeLicense: any;
  clearData: boolean = false;

  selectedOrgan: CompetentOrgan | undefined;
  selectedLicenseType: ProcessData | undefined;
  oldProcessName: string | null;
  proccessId: number | null

  hasAuthorizativeData: boolean = false
  authorizativeDateModel: string = ''
  authorizativeDateCommentsModel: string = ''

  constructor(
    private readonly fb: UntypedFormBuilder,
    private competentOrganService: CompetentOrganService,
    private licensingService: LicensingService,
    private readonly utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NzModalService,
    private message: MessageService,
    private viewContainerRef: ViewContainerRef,
    private store: Store<BreadcrumbState>,
    private projectsV2Service: ProjectsV2Service,
    private alertMessageService: AlertMessageService,
  ) {}


  ngOnInit(): void {
    this.cadProcessLicensing = this.utilService.getAuthorization('insert', 'CADPROCESSLICENSING');
    this.route.params.subscribe((params: Params) => {
      this.projectId = +params['projectId'];
    })

    this.idSelectedOrgan = + (this.route.snapshot.queryParamMap.get('organId') || 0);
    this.idSelectedTypeProcess = +(this.route.snapshot.queryParamMap.get('proccessTypeId') || 0);
    this.oldProcessName = this.route.snapshot.queryParamMap.get('proccessName') || "";
    this.proccessId = +(this.route.snapshot.queryParamMap.get('proccessId') || 0);

    const loggedUser = sessionStorage.getItem("loggedUser");
    this.user = loggedUser ? JSON.parse(loggedUser) : null;
    this.initForm()
    this.getOrganList()
    this.getProject(this.projectId)

  }

  private getProject(projectId: number): void {
    this.loadingProject = true;
    this.projectsV2Service.getProjectByProjectId(projectId).subscribe({
      next: (project: ProjectByIdResponseDto) => {
        this.project = project;
        this.loadingProject = false;
      },
      complete: () => {
        this.dispatchProjectOnStore(this.project);
        this.loadingProject = false
      },
    });
  }
  private dispatchProjectOnStore(projectRequest: any): void {

    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: projectRequest.id
    });

    const pagesArray: Page[] = [
      {
        name: "Projetos",
        url: `/${RoutesEnum.PROJECTS}`
      },
      {
        name: projectRequest.name,
        url: `/${routeProject}`
      },
      {
        name: 'Processos',
        url: `/${routeProject}`
      }
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }

  getOrganList() {
    this.competentOrganService.getCompetentOrgans().subscribe({
      next: (response: CompetentOrgan[]) => {
        this.competentOrgans = response;

        if (this.idSelectedOrgan) {
          this.getLicensesByOrgan(this.idSelectedOrgan)
          const organ = this.competentOrgans.find(org => +(org.id || -1) === this.idSelectedOrgan);
          this.selectedOrgan = organ;
          this.isOrganDisabled = true;
        }
      },
      complete: () => {
        this.loading = false;
      },
    })
  }

  getLicensesByOrgan(idOrgan) {
    this.listOfTypeProcess=[]
    this.licensingService.getLicensesByOrgan(idOrgan).subscribe({
      next: (response: any[]) => {
        this.listOfTypeProcess = response;

        if (this.idSelectedTypeProcess) {
          const licenseType = this.listOfTypeProcess.find(licType => +licType.id == this.idSelectedTypeProcess);
          this.selectedLicenseType = licenseType;
          this.isDisabled = true;

          const procName = this.oldProcessName ? this.oldProcessName : (licenseType as any).description + ' | ';
          this.form.controls['processName'].enable()
          this.form.controls['processName'].setValue(procName);
          this.statusProcessName = true
          this.processName = procName.split(" | ")[1];
          this.verifyIfCanContinue();
        }
      },
    })
  }

  initForm() {
    this.form = this.fb.group({
      licenseType: [null, [Validators.required]],
      processName: new FormControl({value: '', disabled: true}, [Validators.required]),
      authorizativeDate: [null],
      authorizativeDateComments: [null]
    })
    this.statusProcessName = false
  }

  onSelectOrgan(event) {
    this.idSelectedTypeProcess = 0;
    this.selectedLicenseType = undefined;
    if(this.form.controls['processName'].value) {
      this.form.controls['processName'].disable()
      this.form.controls['processName'].setValue('');
      this.clearData = true
      this.statusProcessName = false
    } else {
      this.isDisabled = event ? false : true
      if(!event) {
        this.idSelectedOrgan = 0
        this.isDisabled = false
        this.listOfTypeProcess = []
        this.idSelectedTypeProcess = 0
        this.form.controls['processName'].disable()
        this.form.controls['processName'].setValue('');
        this.statusProcessName = false
        this.isDisabled = true
      }
    }
    if(event){
      this.idSelectedOrgan = event.id
      this.getLicensesByOrgan(this.idSelectedOrgan)
      this.verifyIfCanContinue();
    }
  }

  onSelectProcessType(event) {
    if(!event) {
      this.listOfTypeProcess = []
      this.idSelectedTypeProcess = 0
      this.form.controls['processName'].disable()
      this.form.controls['processName'].setValue('');
      this.statusProcessName = false
      this.getLicensesByOrgan(this.idSelectedOrgan);
      this.verifyIfCanContinue();
    } else {
      this.idSelectedTypeProcess = event.id
      this.enableAndFillInitialProcessName(event.description + ' | ');
      this.selectedLicenseType = event;
    }
  }

  enableAndFillInitialProcessName(initialName: string) {
    this.form.controls['processName'].enable()
    this.form.controls['processName'].setValue(initialName);
    this.selectedNameTypeProcess = this.form.controls['processName'].value;
    this.statusProcessName = true
  }

  onSelectProcessName() {
    let substringProcessName = this.form.controls['processName'].value.split(" | ")[1]
    this.processName = substringProcessName
    this.verifyIfCanContinue();
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    if (!inputElement.value.startsWith((this.selectedLicenseType as any)?.description + ' | ')) {
      inputElement.value = this.form.controls['processName'].value;
    } else {
      this.form.controls['processName'].setValue(inputElement.value)
    }
    this.onSelectProcessName();
  }

  onKeyDown(event: KeyboardEvent): void {

    const inputElement = event.target as HTMLInputElement;

    if(inputElement.selectionStart === 0) {
      event.preventDefault();
      this.onSelectProcessName();
      return
    }

    if (inputElement.selectionStart && inputElement.selectionStart <= ((this.selectedLicenseType as any)?.description + ' | ').length && event.key === 'Backspace') {
      event.preventDefault();
      this.onSelectProcessName();
    }

    if (inputElement.selectionStart && inputElement.selectionStart < ((this.selectedLicenseType as any)?.description + ' | ').length && event.key !== 'Tab') {
      event.preventDefault();
    }

  }

  verifyIfCanContinue() {
    this.canContinue = (this.idSelectedOrgan > 0 && this.idSelectedTypeProcess > 0 && this.processName.length > 1 && (!this.authorizativeDateModel || (this.authorizativeDateModel && this.authorizativeDateCommentsModel !== ''))) ? true : false

  }

  createProcess() {
    if (this.proccessId) {
      this.updateProccess();
      return;
    }

    if(this.authorizativeDateModel && !this.form.controls['authorizativeDateComments'].value) {
      return
    }

    const payload = this.buildPayload()
    this.licensingService.createProcessLicensing(payload).subscribe({
      next: (processCreated) => {
        this.message.showSucessMessage('Processo criado com sucesso!');
        this.goToProcess(this.projectId, processCreated.id);
      },
      error: (error) => {
        const processName = this.form.controls['processName'].value;
        const errorDetails = error?.error?.errors?.details || 'Erro desconhecido';
        this.message.showErrorMessage(
          `Erro ao criar o processo "${processName}": ${errorDetails}`
        );
      }
    });
  }

  updateProccess() {
    if (this.proccessId) {
      const payload = this.buildPayload()
      this.licensingService.updateProcessLicensing(payload, this.proccessId).subscribe({
        next: (proccessUpdated) => {
          this.message.showSucessMessage('Processo atualizado com sucesso!');
          this.goToProcess(this.projectId, proccessUpdated.id);
        },
        error: (error) => {
          this.message.showErrorMessage('Erro ao criar processo: ' + this.form.controls['processName'].value);
        }
      })
    }
  }

  buildPayload(): any {
    let licensesConnections = this.listOfTypeProcess.filter(item => item.child).map(item => {
      return {
        linkedLicenseId: item.linkedLicenseId,
        connectionType: item.type
      }
    });

    return {
      projectId: this.projectId,
      competentOrganId: this.idSelectedOrgan,
      licenseTypeId: this.idSelectedTypeProcess,
      title: this.form.controls['processName'].value,
      userId: this.user.id,
      userLegalRepId: this.listOfTypeProcess[0].userLegalRep?.id,
      protocolDate: this.listOfTypeProcess[0].protocolDate,
      deadline: this.listOfTypeProcess[0].deadline,
      processNumber: this.listOfTypeProcess[0].processNumber,
      licensesConnections: licensesConnections,
      neededDate: (this.authorizativeDateModel || this.authorizativeDateModel !== '') ? moment(this.authorizativeDateModel).format("YYYY-MM-DD") : null,
      neededDateDescription: (this.authorizativeDateCommentsModel || this.authorizativeDateCommentsModel !== '') ? this.authorizativeDateCommentsModel : null,
    }
  }

  cancelProcess() {
    if (this.proccessId) {
      const modal = this.modalService.create<ModalCancelProcessComponent>({
        nzTitle: '',
        nzWidth: 640,
        nzContent: ModalCancelProcessComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: null,
        nzOnCancel: () => {
        modal.destroy();
        },
        nzOnOk: () => {
        this.router.navigate([`/projetos/${this.projectId}`]);
        this.alertMessageService.send('O cadastro do processo foi cancelado.', 'error', true);
        }
      });
    }
  }

  goBack() {

    this.router.navigate([`/projetos/${this.projectId}`]);
  }

  goToProcess(projectId: number, processId: number) {
    const routeEditprocess = buildRoute(RoutesEnum.EDIT_PROCESS_V2, {
      projectId: projectId,
      processId: processId
    });
    this.router.navigate([`/${RoutesEnum.PROJECTS}${routeEditprocess}`]);
  }


  setAuthorizativeDate() {
    if(this.authorizativeDateModel) {
      this.hasAuthorizativeData = true
      this.canContinue = false
    } else {
      this.hasAuthorizativeData = false
      this.authorizativeDateCommentsModel = ''
      this.canContinue = true
    }
  }

  setAuthorizativeDateComments() {
    if(!this.authorizativeDateModel) {
      this.canContinue = true
    } else {
      this.canContinue =  this.authorizativeDateCommentsModel.length >= 1 ? true : false
    }
    this.verifyIfCanContinue()
  }

}
