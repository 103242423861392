<!-- DADOS CONSULTORIA -->
<div class="app-study-team-vale">
    <header>
        <h1 class="title">{{ "suppliers.study-team.vale.title" | translate }}</h1>
    </header>

    <div *ngIf="valeteam && valeteam.length === 0">
        <p-button
            id="btnNewTeam"
            [text]="true"
            label="{{ 'suppliers.study-team.vale.btn-add-new-team' | translate }}"
            icon="pi pi-plus"
            iconPos="left"
            [ngClass]="'colorGreen'"
            (click)="addNewTeamVale()"
            onkeypress="addNewTeamVale()"/>
    </div>

    <div class="vale" *ngIf="valeteam && valeteam.length > 0">

        <div *ngFor="let professional of valeteam" style="margin-bottom: 16px !important;">

            <h1 class="responsibleLabel mt-16">{{ professional['role'] }}</h1>

            <div class=caixaGeral>
                <div class="responsible">
                    <app-user-badge [userName]="professional['userName']" [color]="'#2078A9'"></app-user-badge>
                    <h1 class="responsibleName">{{ professional['userName'] }}</h1>
                </div>

                <div class="caixaDosIcones">
                    <h1 class="responsibleEmail" #emailElement>{{ professional['userEmail'] }}</h1>
                    <div class="menuIconOptions mt-8">
                        <div class="icon"
                            nzTooltipColor="#00a599" 
                            nz-tooltip="{{ tooltipMessage }}"
                            [nzTooltipColor]="tooltipColor"
                            nzTooltipTrigger="hover"
                            nzTooltipClassName="custom-tooltip"
                            (click)="copyEmail()"
                            onkeypress="copyEmail()">
                            <img src="../../../../../../../assets/icons/team-copy-email-icon.svg" alt="Copy Email" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="vale" *ngIf="valeteam.length === 0">
        <span>Equipe ainda não cadastrada.</span>
    </div>
    
</div>
<!-- FIM DADOS CONSULTORIA -->
