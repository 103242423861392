import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment/moment';

@Component({
  selector: 'app-modal-prediction-conclusion',
  templateUrl: './modal-prediction-conclusion.component.html',
  styleUrl: './modal-prediction-conclusion.component.scss'
})
export class ModalPredictionConclusionComponent {

  _cleanValues: boolean = false;
  selectedStatuses: string[] = [];
  _dateInput: any = null;
  _periodInput: any = null;
  @Input() behindSchedule: boolean = false;
  @Input() pending: boolean = false;
  @Input() withoutProtocol: boolean = false;
  @Input() closeToRenovation: boolean = false;
  @Input() visible: boolean = false;
  @Input() hasBehindSchedule: boolean = false;
  @Input() hasPending: boolean = false;
  @Input() hasWithoutProtocol: boolean = false;
  @Input() hasCloseToRenovation: boolean = false;

  @Input()
  set dateInput(value: any) {
    this._dateInput = value;
    this.validateDateAndPeriod();
  }
  get dateInput() {
    return this._dateInput;
  }

  @Input()
  set periodInput(value: any) {
    this._periodInput = value;
    this.validateDateAndPeriod();
  }
  get periodInput() {
    return this._periodInput;
  }

  @Input()
  set cleanValues(value: boolean) {
    if (value !== this._cleanValues) {
      this._cleanValues = value;
      this.onClean();
    }
  };

  @Output() changeApplyFilter = new EventEmitter<any>();
  @Output() changeCleanValues = new EventEmitter<any>();

  minDate: Date | undefined;

  validateDateAndPeriod() {
    if (this._dateInput && this._periodInput) {
      const dateFormatted = moment(this._dateInput).format("YYYY-MM-DD");
      const periodFormatted = moment(this._periodInput).format("YYYY-MM-DD");

      if (dateFormatted === periodFormatted) {
        this._periodInput = null;
      } else {
        this._periodInput = [this._dateInput, this._periodInput];
        this._dateInput = null;
      }
    }
  }

  onClean() {
    this._dateInput = null;
    this._periodInput = null;
    this._cleanValues = false;
    this.behindSchedule = false;
    this.pending = false;
    this.withoutProtocol = false;
    this.closeToRenovation = false;
    this.changeCleanValues.emit(true);
  }

  onApplyFilter() {
    const payload: any = {};

    if (this._dateInput) {
        const date = moment(this._dateInput).format("YYYY-MM-DD");
        payload.date = date;
        payload.period = date;
    } else if (this._periodInput) {
        payload.date = moment(this._periodInput[0]).format("YYYY-MM-DD");
        payload.period = this._periodInput[1] ? moment(this._periodInput[1]).format("YYYY-MM-DD") : moment(this._periodInput[0]).format("YYYY-MM-DD");
    }

    if (this.hasBehindSchedule) {
        payload.behindSchedule = this.behindSchedule;
    }

    if (this.hasPending) {
        payload.pending = this.pending;
    }

    if (this.hasWithoutProtocol) {
        payload.withoutProtocol = this.withoutProtocol;
    }

    if (this.hasCloseToRenovation) {
        payload.closeToRenovation = this.closeToRenovation;
    }

    this.changeApplyFilter.emit(payload);
  }

  updateDate(): void {
    this._periodInput = null;
  }

  updatePeriod(): void {
    this._dateInput = null;
  }

  updateWithoutProtocol(): void {
    if (this.hasWithoutProtocol) {
      this.withoutProtocol = false;
    }
  }
}
