import { Component, Input, TemplateRef, inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrl: './generic-modal.component.scss'
})
export class GenericModalComponent {
  readonly #modal = inject(NzModalRef);

  @Input() iconName: string | null;
  @Input() modalTitle!: string;
  @Input() modalSubTitle: string;
  @Input() modalText: string;
  @Input() warningText: string;
  @Input() informativeText: string | null;
  @Input() actionConfirmationText: string | null;
  @Input() cancelationButtonText: string = "Não";
  @Input() confirmButtonText: string = "Sim";
  @Input() customComponent?: TemplateRef<any>;
  @Input() parentContext?: any;

  errorMessage: string = "";

  cancelAction() {
    this.#modal.close(false);
  }

  executeAction() {
    if (this.parentContext && !this.parentContext?.modalValidation?.()) {
      this.errorMessage = "Realize as ações obrigatórias";
      setTimeout(() => {
        this.errorMessage = ""
      }, 2000)
      return;
    }

    this.#modal.close(true);
  }
}
