<div class="app-modal-cancel-consultancy-configuration">
    <div class="content">
        <div class="container__close">
            <img class="plus__ico" src="/assets/icons/icon_close_green24.svg" alt="fechar" (click)="onCancel()" />
        </div>

        <div class="alignCenter">
            <div class="content__img">
                <img class="plus__ico" src="/assets/icons/icon_caution_circle_warning_red.svg" alt="info" />
            </div>
            <h2 class="title">
                @if (titleModal.length <= 0) {
                    @if (isCancellation) {
                        {{ ("suppliers.consultancy.modal-cancellation.title" | translate)}}
                    } @else {
                        {{ ("suppliers.consultancy.modal-exclusion.title" | translate)  }}
                    }
                } @else {
                    {{ titleModal }}
                }
            </h2>
        </div>


        <div class="content__text">
            <p *ngIf="!!additionalText" class="additional_text">{{ additionalText }}</p>
            <p class="bold" *ngIf="mainText">{{ mainText }}</p>
            <p class="bold" *ngIf="!mainText && typeToExclude==='con'"> Deseja mesmo excluir a consultoria <strong>{{ optionToExclude }}</strong> da lista?</p>
            <p class="bold" *ngIf="!mainText && typeToExclude==='deliver'"> Deseja mesmo excluir o entregável <strong>{{ optionToExclude }}</strong> da lista?</p>
            <p class="bold" *ngIf="!mainText && typeToExclude==='technical-proposals'"> Deseja mesmo excluir o arquivo <strong>{{ optionToExclude }}</strong> da lista?</p>
            <p class="bold" *ngIf="!mainText && typeToExclude==='consultancy-team'"> Deseja mesmo excluir <strong>{{ optionToExclude }}</strong> da equipe?</p>
            <p class="info" [innerHTML]="isCancellation ?
                ('suppliers.consultancy.modal-cancellation.info' | translate) :
                typeToExclude==='consultancy-team' ? 
                ('suppliers.consultancy.modal-exclusion.info-team' | translate) : 
                ('suppliers.consultancy.modal-exclusion.info' | translate)"></p>
        </div>

        <div class="content__buttons">
            <app-button-icon-secondary
                customClassBtn="padding: 0 20px; margin-right: 15px;"
                [id]="'btn-icon-secondary'"
                [text]="'Não'"
                altText="Não"
                (click)="onCancel()">
            </app-button-icon-secondary>

            <app-button-icon-primary
                customClassBtn="padding: 0 20px;"
                [id]="'btn-icon'"
                [text]="acceptButtonText ? acceptButtonText : 'Sim, excluir'"
                altText="Sim, excluir"
                (click)="onClickConfirm(optionToExclude)">
            </app-button-icon-primary>
        </div>

    </div>
</div>
