<div nz-row nz-col nzSpan="24" style="display: flex; height: 85vh !important">

  <div nz-col nzSpan="24" class="ali-center-flex" style="margin-top: 40px">

    <div class="icone">
      <img src="../../../../assets/images/modal_inicial_consultoria.svg" alt="modal consultoria"
      class="modal_inicial_consultoria"/>

    </div>

    <div style="width: 270px; text-align: left">
      <h1 class="titulo">Olá, {{ userName | translate}}</h1>
      <h1 class="titulo" style="margin-top: 24px; margin-bottom: 8px">Boas vindas ao <br> Smart Licensing!</h1>
      <small class="textoFixo"> Seu novo canal de comunicação com a Vale para elaboração e revisão de estudos
        ambientais</small>
    </div>

  </div>

  <div nz-col nzSpan="24" class="ali-center-flex" style="margin-bottom: 40px">
    <div style="text-align: left; margin-top: 40px">
      <small class="subtitle"> O que você pode fazer aqui</small>
      <ul class="textoFixo" style="text-align: left; margin-top: 16px;">
        <li>Acessar e consumir todos os materiais fornecidos para o estudo</li>
        <li>Enviar e acompanhar a revisão do estudo</li>
      </ul>
    </div>
  </div>

  <button
    id="btnCancelWelcomeModal"
    type="button"
    class="btn-sl btn-sl-grey buttonCancel"
    (click)="executeButtonAction()">
    Vamos começar
  </button>
</div>
