<div class="app-list-item">
  <ng-container *ngTemplateOutlet="iconComponent; context: iconComponentContext"></ng-container>
  
  <div class="item-content-container">
    <div class="item-values">
      <strong class="item-title" [nz-tooltip]="checkIfTextExceedsTwoLines() ? itemTitle : ''">{{ itemTitle }}</strong>
      <span class="item-description">{{ itemDescription }}</span>
    </div>

    <div alt="Chevron open item element" class="arrow_element"></div>
  </div>
</div>