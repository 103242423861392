import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './view/main/main.component';
import {RoutesEnum} from 'src/app/shared/enum/routes.enum';
import {HistoricChatsComponent} from './view/historic-chats/historic-chats.component';
import {AuthGuard} from 'src/app/core/auth/guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: HistoricChatsComponent
      },
      {
        path: RoutesEnum.HISTORIC_CHATS_AI,
        canActivate: [AuthGuard],
        component: HistoricChatsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatIaRoutingModule { }
