import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {models} from "powerbi-client";
import {AuthService} from "../../../../core/auth/service/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-dashboard-negocios',
  templateUrl: './dashboard-negocios.component.html',
  styleUrls: ['./dashboard-negocios.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNegociosComponent implements OnInit{

  workspace: string = environment.workspace
  dashNegocios: string = environment.dashNegocios

  embedConfig: any;
  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {

    this.authService.GetServicePrincipalAccessToken(this.dashNegocios,this.workspace).subscribe({
      next: data => {
        if(data){
          this.embedConfig = {
            type: "report",
            id: this.dashNegocios,
            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=b1a4753c-5904-4950-ab04-16cc8084d8ae",
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              layoutType: models.LayoutType.Master,
              zoomLevel: 0.75,
              customLayout: {
                pageSize: { type: models.PageSizeType.Cortana},
                displayOption: models.DisplayOption.FitToWidth,
                reportAlignment: models.ReportAlignment.Center
              },
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            }
          }
        }
      }
    })

  }
}
